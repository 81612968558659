import Vue from 'vue';
import Router from 'vue-router';
import Account from '@/Pages/Account/routes';
import Profile from '@/Pages/Profile/routes';
import Company from '@/Pages/Company/routes';
import People from '@/Pages/People/routes';
import Materials from '@/Pages/Materials/routes';
import Appointments from '@/Pages/Appointments/routes';
import Board from '@/Pages/Board/routes';
import Reports from '@/Pages/Reports/routes';
import Pos from '@/Pos/routes';
import Easy from '@/Pages/Easy/routes';
import Welcome from '@/Pages/Welcome/routes';
import MyEarnings from '@/Pages/MyEarnings/routes';
Vue.use(Router);
export default new Router({
    mode: process.env.VUE_APP_ROUTER_MODE,
    base: process.env.VUE_APP_BASE_URL,
    routes: [{
            path: '/',
            component: () => import('./Layouts/Default.vue'),
            children: [
                {
                    path: '',
                    component: () => import('./views/Home.vue'),
                },
                {
                    path: '/settings',
                    name: 'settings',
                    component: () => import('@/Pages/Settings/Index.vue'),
                },
                {
                    path: '/',
                    component: () => import('./views/Home.vue'),
                    children: [{
                            path: '',
                            name: 'home',
                            component: () => import('./views/Home.vue')
                        }],
                },
            ],
            meta: {
                requiresAuth: true,
            }
        },
        Account,
        Profile,
        Company,
        People,
        Materials,
        Appointments,
        Board,
        Reports,
        Easy,
        Pos,
        Welcome,
        MyEarnings,
    ]
});
