// noinspection JSNonASCIINames,NonAsciiCharacters
export default {
    Planned: "Suunniteltu",
    Arrived: "Saapui",
    arrived: "Saapui",
    Appointments: "Ajanvaraukset",
    Board: "Lauta",
    List: "Lista",
    Materials: "Materiaalit",
    Store: "Varasto",
    Calendar: "Kalentari",
    Customers: "Asiakkaat",
    customers: 'Asiakkaat',
    Patients: "Potilaat",
    Patient: "Potilas",
    Customer: "Asiakas",
    Custom: "Mukautettu",
    Products: "Tuotteet",
    Product: "Tuote",
    Categories: "Kategoriat",
    Category: "Kategoria",
    'Category name': 'Kategorian nimi',
    'Ex. Consumables': 'Kulutustarvikkeet',
    Brands: "Brändit",
    Brand: "Brändi",
    Suppliers: "Toimittajat",
    Supplier: "Toimittaja",
    Subject: 'Aihe',
    Body: 'Сообщение',
    'Appointment time': 'Ajanvarausaika',
    'Appointment date': 'Ajanvarauspäivä',
    'Make default': 'Laita oletusarvoksi',
    'Appointment notification template': 'Tapaamisilmoitusmalli',
    'Debt return notification template': 'Velanpalautusilmoitusmalli',
    Services: "Palvelut",
    'Services & prices': 'Palvelut ja hinnat',
    Profile: "Profiili",
    'My profile': "Minun profiili",
    profile: "Profiili",
    Team: "Tiimi",
    "ICD-10": "ICD-10",
    Reports: "Raportit",
    Summary: "Yhteensä",
    History: "Historia",
    Payments: "Maksut",
    People: "Ihmiset",
    'New customer': "Uusi asiakas",
    'New appointment': "Uusi tapaaminen",
    Appointment: 'Tapaaminen',
    Confirmed: "Vahvistettu",
    Late: "Myöhään",
    'Not confirmed': "Ei vahvistettu",
    confirmed: "Vahvistettu",
    Completed: "Valmis",
    completed: "valmis",
    Complete: "Saattaa loppuun",
    'No show': "Ei tullut",
    'noshow': "Ei tullut",
    Today: 'Tänään',
    Tomorrow: 'Huomenna',
    'Next week': 'Ensi viikko',
    'Next month': 'Ensi kuukasi',
    Yesterday: 'Eilen',
    'This week': 'Tämä viikko',
    'This month': 'Tämä kuukausi',
    'Previous week': "Viime viikko",
    'Previous month': "Viime kuukausi",
    'Custom range': "Määritä ajanjakso",
    'By date': "Päivämäärän mukaan",
    'Team member': "Tiimin jäsen",
    'Team members': "Tiimin jäsenet",
    'By guarantee': 'Takuulla',
    guarantee: 'Takuu',
    Guarantee: 'Takuu',
    cash: "Käteinen raha",
    credit: "Luotolla",
    card: "kortilla",
    other: "Muut",
    userBalance: 'Asiakkaan saldo',
    mobilePayment: 'Puhelinmaksu',
    Presets: 'Mallit',
    Preset: 'Malli',
    'New balance': 'Saldo jälkeen',
    'Debt info': 'Velan takaisinmaksu',
    'Preset has been updated': 'Malli päivitetty',
    'Preset has been created': 'Malli lisätty',
    'Preset has been applied': 'Malli sovelletaan',
    bank: "Pankki",
    Address: "Osoite",
    "First name": "Etunimi",
    "Last name": "Sukunimi",
    "Middle name": "Toinen nimi",
    "Patient first name": "Potilaan etunimi",
    "Patient last name": "Potilaan sukunimi",
    "Patient middle name": "Potilaan toinen nimi",
    Details: "Lisää",
    General: "Perus",
    Gender: "Sukupuoli",
    Male: "Mies",
    Female: "Nainen",
    male: "mies",
    female: "Nainen",
    More: "Lisää",
    Birthdate: "Syntymäpäivä",
    'Birth day': 'Syntymäpäivä',
    Phone: 'Puhelin numero',
    Complaints: 'Valitukset',
    'Complaints about': 'Valituksia aiheesta',
    Complaint: 'Valitus',
    Objectively: "Objektiivisesti",
    Exam: "Vastaanotto",
    Exams: "Vastaanotot",
    'New exam': "Uusi vastaanotto",
    'Customer card': 'Asiakaskortti',
    'All exams history will be here': 'Tähän osioon on tallennettu koko tarkastushistoria',
    'Date created': 'Lisätty',
    'Customer passport': "Asiakkaan passi",
    'Nothing yet': 'Ei mitään vielä',
    Code: 'Koodi',
    Shortcode: 'Lyhyt koodi',
    Description: 'Kuvaus',
    Language: 'Kieli',
    Label: "Nimi",
    manage: "Ohjaus",
    'Complaints DB': 'Valitusluettelo',
    'Complaint has been saved': 'Valitus tallennettu',
    'Complaint has been created': 'Valitus lisätty',
    'Objectively DB': 'Käsikirja objektiivisesti',
    References: "Referenssit",
    Edit: "Muokkaa",
    Timeline: "Historia",
    Notes: "Huomautuksia",
    Note: "Huomautus",
    Billing: "Maksut",
    Storage: "Tiedostot",
    Search: "Hae",
    Name: "Nimi",
    Diagnosis: "Diagnoosi",
    Diagnoses: "Diagnoosit",
    Treatment: "Hoito",
    Consumables: 'Kulutustarvikkeet',
    Service: 'Услуга',
    Price: 'Hinta',
    'Retail price': 'Vähittäismyyntihinta',
    'Retail price per': 'Vähittäismyyntihinta hintaan',
    'Duration (Min)': 'Kesto (min)',
    'Buffer time (Min)': 'Puskuriaika',
    'Ex.: A001': 'Esim.: A001',
    'Treatment name': 'Menettelyn nimi',
    'Treatment duration': 'Menettelyn kesto',
    'Time required to prepare to the next treatment': 'Aika valmistautua seuraavaan menettelyyn',
    'Ex.: Primary consultation': 'Esim.: Alkuperäinen asiantuntijakonsultaatio',
    'Short description of treatment': 'Lyhyt kuvaus menettelystä',
    Cancel: 'Peruuttaa',
    Save: 'Tallentaa',
    Delete: 'Poistaa',
    Ok: 'Valmis',
    'Public treatment': "Näytä kalenterissa",
    'Public treatments will be published': "Menettely näytetään sen suorittaville työntekijöille",
    'Services & treatments': 'Palvelut ja menettelyt',
    'Loading...': 'Ladataan...',
    Create: 'Luoda',
    Duration: 'Kesto',
    'New treatment': 'Uusi menettely',
    min: 'min.',
    minutes: 'min.',
    h: 't.',
    Upcoming: 'Aikataulutettu',
    Previous: 'Mennyt',
    All: 'Kaikki',
    New: 'uusi',
    cancelled: 'Peruutettu',
    Started: 'Vastaanotossa',
    started: 'Vastaanotossa',
    'Total scheduled': 'Käyntejä yhteensä',
    'Start at': 'Alkaa klo',
    'Appointment at': 'Запись на',
    Status: 'Tila',
    Staff: 'Työntekijä',
    Active: 'Aktiivinen',
    Archived: 'Arkisto',
    Age: 'Ikä',
    Image: 'Kuva',
    Date: 'Päivämäärä',
    'No confirmed appointments': 'Ei vahvistettuja tapaamisia',
    'No waiting appointments': 'Ei vireillä olevia tapaamisia',
    'No waiting online appointments': 'Ei odotusaikoja verkossa',
    'No started appointments': 'Ei aloitettuja tapaamisia',
    'In treatment': 'Menettelyssä',
    'Drag and drop to upload content!': 'Lataa vetämällä',
    '...or click to select a file from your computer': '... tai napsauta etsiäksesi tietokoneella',
    '...or press CTRL + V to paste from clipboard': '...tai paina CTRL + V liittääksesi leikepöydältä',
    'Ex.:John': 'Esim.: Leo',
    'Ex.:Doe': 'Esim.: Korhonen',
    'Ex.:Kingsport': 'Esim.: Johan',
    'Select date': 'Valitse päivämäärä',
    'Set photo': 'Valitse valokuva',
    'Remove image': 'Poistaa kuvaa',
    'Blacklist customer': 'Estä',
    Blacklisted: 'Estännyt',
    'Blacklisted users can not make new appointment. But administrator still can make an appointment.': 'Lisää epäilyttävien tai usein ohittavien asiakkaiden mustalle listalle',
    'Enable general SMS notifications': 'Ota yleiset tekstiviesti-ilmoitukset käyttöön',
    'If active, the customer agreed to receive general SMS messages': 'Asiakas saa ilmoitukset ilmoittautumisista',
    'Enable promo SMS notifications': 'Ota promoilmoitukset käyttöön',
    'If active, the customer agreed to receive promo SMS messages': 'Asiakas saa viestejä kampanjoista ja muista tapahtumista',
    Add: 'Lisätä',
    'Add supply': 'Lisää toimitus',
    'Add product': 'Lisää tuote',
    'Add category': 'Lisää kategoria',
    'Add brand': 'Lisää tuotemerkki',
    'Add supplier': 'Lisää toimittaja',
    'Ex.: Caries': 'Esim.: karies',
    Select: 'Valitse',
    'Select diagnosis': 'Valitse diagnoosi',
    'Preliminary diagnosis': 'Alustava diagnoosi',
    'Final diagnosis': 'Lopullinen diagnoosi',
    'Select from templates': 'Valitse malleista',
    'Save as template': 'Tallenna malliksi',
    Use: 'Käyttää',
    'Service name': 'Palvelun nimi',
    'Ex.: Consultation': 'Esim.: Konsultointi',
    'Describe service': 'Kuvaile palvelua',
    'Public section': 'Näytä kalenterissa',
    'Public sections will be published.': 'Palvelu näytetään sen suorittaville työntekijöille',
    Rank: 'Sijoitus',
    'Ex.: 0-10': 'Esim.: 0-10',
    Visits: 'Vierailuja',
    'No shows': 'Poissaolot',
    Options: 'Vaihtoehdot',
    Objectivly: 'Objektiivisesti',
    'Are you sure?': 'Oletko varma?',
    'Do you really want to delete the record ?': 'Hyväksytkö tapaamisen poistamisen?',
    Specialty: 'Erikoisuus',
    'Ex.: Therapist': 'Esim.: Terapeutti',
    'Ex.: therapist': 'Esim.: Terapeutti',
    Map: 'Kartta',
    Missing: 'Puuttuu',
    Root: 'Juuri',
    Caries: 'Karies',
    Pulpitis: 'Pulpitis',
    Periodontitis: 'Parodontiitti',
    Plombed: 'Tiiviste',
    Parodontoz: 'parodontaalinen sairaus',
    Shakiness: 'Liikkuvuus',
    Crown: 'kruunu',
    Intact: 'Ehjä',
    Depulped: 'Tyhjennetty',
    'Primary adentia': 'Primaarinen adentia',
    'Secondary adentia': 'Toissijainen adentia',
    Implant: 'istuttaa',
    'Cement caries': 'Kariessementtiä',
    'Ortopedical status': 'Ortopediset',
    'Therapeutic status': 'Terapeuttinen',
    'Anchor pin': 'Ankkuritappi',
    Vinir: 'Viilu',
    Retreat: 'Vetäytyä',
    Exclude: 'Poistaa',
    Inset: 'Välilehti',
    Facet: 'Facet',
    'Parodontal status': 'Parodontaalin kunto',
    'Treatment required': 'Tarvitsee hoitoa',
    'Treatment not required': 'Ei tarvitse hoitoa',
    'Ex. Apple': 'Esim. Apple',
    Bundles: 'Kimput',
    Bundle: 'Kimppu',
    'Exam created': 'Uusi tutkimus',
    Featured: 'Lisää suosikkeihin',
    'Treatment plan': 'Hoitosuunnitelma',
    'Select treatment': 'Valitse hoito',
    'Add treatment': 'Lisää hoito',
    'Tooth #': 'Hammas #',
    'Total': 'Yhteensä',
    'Root canals': 'Kanavat',
    'Time': 'Aika',
    'mSv': 'mSv',
    'No channel measurement': 'Juurikanavia ei ole vielä mitattu',
    'Includes services or products': 'Sisältää palvelut tai tuotteet',
    'Print': 'Tiiviste',
    Contract: 'Sopimus',
    Others: 'Muut',
    Agreement: 'Sopimus',
    'Add item': 'Muita kohteita',
    'No slots': 'Ei paikkoja',
    Dates: 'Päivämäärät',
    Intraoral: 'Intraoraalinen',
    intraoral: 'Intraoraalinen',
    Orthopantomogram: 'Ortopantomogrammi',
    orthopantomogram: 'Ortopantomogrammi',
    'CT scan': 'CТ',
    'cT scan': 'CТ',
    'Client application': 'TR-Client sovellus',
    'Company EIN': 'OKPO koodi',
    'Add service': 'Lisää palvelu',
    'Add another appointment': 'Lisää uusi päivämäärä',
    'service time': 'palveluaika',
    Xrays: 'röntgenkuvaus',
    Xray: 'röntgenkuvaus',
    'New xray': 'Uusi röntgenkuvaus',
    Dose: 'Annos',
    Quantity: 'Määrä',
    Type: 'Tyyppi',
    'Record created': 'Sisäänkirjaus lisätty',
    'Record updated': 'Viesti päivitetty',
    'Xray created': 'Röntgen lisätty',
    'Invoice created': 'Lasku tehty',
    'Customer merged': 'Potilas yhtenäinen',
    'Ex.: Lc': 'Esim.: Lc',
    'Health card': 'Terveyskortti',
    Stock: 'Varasto',
    Supply: 'Toimittaa',
    Supplies: 'Tarvikkeet',
    Pricing: 'Hinnat',
    'Supply price': 'Toimitushinta',
    'Enable product pricing': 'Hintahallinta',
    'Enable stock management': 'Varastonhallinta',
    'Enable supply management': 'Toimitusketjun hallinta',
    'Product name': 'Tuotteen nimi',
    'Ex.:Medical glove': 'Esim.: Lääketieteelliset käsineet',
    'Barcode': 'Viivakoodi',
    'SKU': 'toimittajan koodi',
    'Ex.:12345678': 'Esim.: 12345678',
    'Ex.:AC12345': 'Esim.: AC12345',
    'Supply date': 'toimituspäivämäärä',
    'Expire date': 'Paras ennen',
    'Use current supply': 'Kuluta tästä tarjonnasta',
    'Supply quantity': 'Määrä toimituksessa',
    'The supply will be forced to use in checkout and reports': 'Toimitusta käytetään nykyisenä, kulut veloitetaan tästä toimituksesta.',
    'Required minimum at store': 'Vähimmäismäärä',
    'Measurement': 'Yksikkö mitat',
    'Supplier details': 'Toimittajan tiedot',
    'Supplier name': 'Toimittajan nimi',
    'Ex. Main supplier': 'Esim.: Päätoimittaja',
    'Supplier notes': 'Huomautuksia',
    'Ex.: Call after 10 AM': 'Esim.: Soita klo 10 jälkeen',
    'Contacts': 'Yhteystiedot',
    'Contact name': 'Yhteyshenkilö',
    'Ex. John Park': 'Esim.: Leo Korhonen',
    'Primary phone': 'Päänumero',
    'Secondary phone': 'Lisänumero',
    'Security': "Turvallisuus",
    'Schedule': "Ajoittaa",
    'Website': 'Verkkosivusto',
    'Street': 'Katu',
    'City': 'Kaupunki',
    'State': 'Alue',
    'ZIP / Post code': 'Postinumero',
    'Country': 'Valtio',
    'Ex.: Tashkent': 'Esim. Helsinki',
    'Billing details': 'Laskutustiedot',
    'Legal information': 'Lakitiedot',
    'Legal information provided in this section will be used for all your invoices and other legal documents': 'Tässä kohdassa määriteltyjä tietoja käytetään oikeudellisissa asiakirjoissa ja laskuissa.',
    'Legal name': 'Laillinen nimi',
    'Enter notes for all invoices': 'Huomautus laskuista',
    'Company timezone': 'Aikavyöhyke',
    'It is important to select correct timezone.': 'On tärkeää valita oikea aikavyöhyke',
    'Interface default language': 'Järjestelmän oletuskieli',
    'Users can change in their profile.': 'Jokainen järjestelmän käyttäjä voi muuttaa asetuksia',
    'Currency': 'Valuutta',
    'For all services calculations': 'Käytetään kaikissa laskelmissa',
    'Contact information': 'Yhteystiedot',
    'Social networks': 'Sosiaaliset verkostot',
    'Ml': 'Ml.',
    'ml': 'ml.',
    'Mg': 'Mg.',
    'mg': 'mg.',
    'Sm': 'Cm.',
    'sm': 'cm.',
    'G': 'Gramma',
    'g': 'g.',
    'Units': 'Kpl.',
    'units': 'kpl.',
    Pack: 'Paketti',
    'pack': 'paketti.',
    'Ex.:100': 'Esim.: 100',
    'Minimum': 'Minimi',
    'Per serving': 'Osa',
    'To buy': 'Ostaa',
    'product': {
        'lowStock': '<b>{product.label}</b> loppumassa'
    },
    'No new notifications': 'Ei uusia ilmoituksia',
    'In waiting list': 'Odotuslistalla',
    'Waitlist': 'Odotuslista',
    'In buy list': 'Ostoslistalla',
    'Ex.: Agreement': 'Esim.: Договор',
    'Documents': 'Dokumentit',
    'Company': 'Yhtiö',
    'Title': 'Otsikko',
    'Content': 'Sisältö',
    'Source': 'Lähde',
    'Sources': 'Lähteet',
    'Campaign': 'Kampanja',
    'Referer': 'Tuli:',
    'Requires value': 'Arvo vaaditaan',
    'Media': 'Media',
    'Media manager': 'Median hallinta',
    'Treatments': 'Hoito',
    'Take a photo': 'Otta kuvaa',
    'Select Device': 'Valitse laite',
    'Logout': 'Ulos',
    'Leave a comment': 'Jätä muistiinpano',
    'Save note': 'Tallenna muistiinpano',
    'Password': 'Salasana',
    'Current password': 'Nykyinen salasana',
    'New password': 'Uusi salasana',
    'Confirm new password': 'Vahvista salasana',
    'Update password': 'Päivitä salasana',
    'Password suggestions': 'Salasanaehdotukset',
    'To create a new strong password, you have to meet all of the following requirements:': 'Suojatun salasanan on täytettävä seuraavat ehdot:',
    'Minimum 8 character': 'Vähintään 8 merkkiä',
    'At least one special character (#%.,)': 'Vähintään yksi erikoismerkki (#%,)',
    'At least one number': 'Ainakin yksi numero',
    'Can’t be the same as a previous password': 'Ei käytetty ennen',
    'Update schedule': 'Päivitä aikataulu',
    Monday: 'Maanantai',
    Tuesday: 'Tiistai',
    Wednesday: 'Keskiviikko',
    Thursday: 'Torstai',
    Friday: 'Perjantai',
    Saturday: 'Lauantai',
    Sunday: 'Sunnuntai',
    Notifications: 'Ilmoitukset',
    'View all': 'Näytä kaikki',
    'Search client': 'Asiakashaku',
    'Appointment schedule': 'Uusi tapaaminen',
    Repeat: 'Toistaa',
    Frequency: 'Taajuus',
    Ends: 'Loppuu',
    'After {value} times': '{value}x kerran jälkeen',
    Repeats: 'Toistaa',
    'Every {value} days': 'Joka {value} päivä',
    'Every 5 days': 'Joka 5 päivä',
    Weekly: 'Kerran viikossa',
    'Every {value} weeks': 'Joka {value}  vikkoa',
    Monthly: 'Kerran kuukaudessa',
    'Every {value} months': 'Joka {value} kuukautta',
    'Every 2 months': 'Joka 2 kuukautta',
    'Every 3 months': 'Joka 3 kuukautta',
    'Every 4 months': 'Joka 4 kuukautta',
    'Month': 'Kuukausi',
    Yearly: 'Kerran vuodessa',
    'Do not repeat': 'Älä toista',
    Daily: 'Joka päivä',
    'Specific date': 'Päivämäärä',
    Ongoing: 'Koko ajan',
    'View Appointment': 'Tallenteen katsominen',
    Close: 'Kiinni',
    Checkout: 'Lasku',
    'Payment method': 'Maksutapa',
    'Payment amount': 'Maksun määrä',
    'Amount': 'Summa',
    'Balance': 'Saldo',
    'Payment successful': 'Maksu suoritettu',
    'Cancel appointment?': 'Perutaanko tapaamista?',
    'Cancel appointment': 'Peruuta tapaamista',
    Socials: 'Sosiaaliset',
    Settings: 'Asetukset',
    Builder: 'Konfiguraattori',
    'General Details': 'Perustieto',
    'Company name': 'Yrityksen nimi',
    'Your company brand/known name.': 'Brändisi/yrityksesi nimi',
    'Ex.:ProfiDental': 'Esim.: ProfiDental',
    'Company description': 'Yrityksen kuvaus',
    'Describe your company in few words so no one wants to read long text.': 'Muutama sana yrityksestäsi',
    'Ex.: We are experts in dental care': 'Esim.: Olemme hammaslääketieteen asiantuntijoita',
    'Public company': 'Julkinen yhtiö',
    'Making your profile public means that anyone on the 32Desk network will be able to find you': 'Julkiset yhtiöt näkyvät 32Desk-hakemistoissa',
    'No payments yet': 'Ei vielä maksuja',
    'Access': 'Pääsy',
    'Payment methods': 'Maksutavat',
    'Cash': 'Käteinen raha',
    'Bank': 'Pankkisiirto',
    'Card': 'Kortilla',
    'Credit': 'Luotolla',
    'Other': 'Muu',
    'Invoice': 'Lasku',
    'Invoice #': 'Lasku #',
    'Invoiced from': 'Laskutettu alkaen',
    'Invoiced ID': 'Lasku numero',
    'Invoiced to': 'Lasku',
    'Cost': 'Hinta',
    'from': 'alkaen',
    'Total amount due': 'Maksettava yhteensä',
    'Set password': 'Aseta uusi salasana',
    'Username': 'Käyttäjätunnus',
    'Role': 'Rooli',
    Doctor: 'Lääkäri',
    Administrator: 'Järjestelmänvalvoja',
    Director: 'Johtaja',
    'Finance manager': 'Talousjohtaja',
    'Item has been saved': 'Merkintä tallennettu',
    'Item has been created': 'Merkintä lisätty',
    'Hide past': 'Piilota menneisyys',
    Color: 'Väri',
    Manipulations: 'Manipuloinnit',
    Manipulation: 'Manipulointi',
    'Ex.: Anesthesia': 'Esim.: Anestesia',
    'Select manipulations': 'Valitse manipulaatiot',
    'Add manipulation': 'Lisää manipulointi',
    'Please select a customer': 'Valitse käyttäjä',
    'Week': 'Viikko',
    'Day': 'Päivä',
    'Days': 'Päivät',
    'Hours': 'Tunnit',
    'Template': 'Malli',
    'Time templates': 'Aikamallit',
    'New template': 'Uusi malli',
    'Add time template': 'Lisää aikamalli',
    'Minutes': 'minuutit',
    '3 Days': '3 päivää',
    'mins ahead': ' | minuutti ennen | {n} minuutti ennen | {n} minuutti ennen',
    'hours ahead': ' | {n} tunti ennen | {n} tunti ennen | {n} tunti ennen',
    'days ahead': ' | {n} päivää ennen | {n} päivää ennen | {n} päivää ennen',
    'weeks ahead': ' | {n} viikkoa ennen | {n} viikkoa ennen | {n} viikkoa ennen',
    'months ahead': ' | {n} kuukautta ennen | {n} kuukautta ennen | {n} kuukautta ennen',
    'in days': ' | {n} päivä jälkeen | {n} päivää jälkeen | {n} päivää jälkeen',
    'in weeks': ' | {n} viikkoa jälkeen | {n} viikkoa jälkeen | {n} viikkoa jälkeen',
    'in months': ' | {n} kuukautta jälkeen | {n} kuukautta jälkeen | {n} kuukautta jälkeen',
    'Notify client': 'Ilmoita asiakkaalle',
    'Note created': 'Muistiinpano luotu',
    Browse: 'Valitse listasta',
    'Please select final diagnonsis': 'Valitse lopullinen diagnoosi',
    'Sign in': 'Kirjaudu sisään',
    'Access to your dashboard': 'Доступ в систему управления',
    'Forgot password?': 'Unohditko salasanasi?',
    'Enter your password': 'Tilisi salasana',
    'Logging out': 'Kirjaudu ulos',
    'See you soon': 'Odotamme sinua edelleen',
    'Done': 'Valmis',
    'User Activities': 'Toimintaloki',
    'Value': 'Merkitys',
    // 'N/A': '-',
    'Select diagnosis from ICD-10 list': 'Valitse diagnoosi ICD-10:stä',
    'To Be Done': 'Tarve tehdä',
    Action: 'Toiminta',
    'Add payment': 'Lisää maksu',
    'Cancellation reason. Ex.: Wrong payment': 'Peruuttamisen syy Esim.: Väärä maksu',
    'Ex.: Treatment of caries': 'Esim. Yksinkertaisen karieksen hoito',
    'Payment cancellation': 'Maksun peruutus',
    'Payment cancelled': 'Maksu peruutettu',
    'Cancelled': 'Peruutettu',
    'Total paid': 'Maksettu yhteensä',
    'Approved': 'Vahvistettu',
    'Explanation': 'Kuvaus',
    'User Balance': 'Käyttäjän saldo',
    'Debtors': 'Velalliset',
    'Outdated': 'Myöhässä',
    'Tags': 'Tunnisteet',
    'Payment status': 'Maksutila',
    'Payment from balance': 'Maksu tililtä',
    'Debt details': 'Velan tiedot',
    'Total Payment': 'Maksettava yhteensä',
    'Paid amount': 'Maksettu',
    'By gender': 'Sukupuolen mukaan',
    Income: 'Tulossa',
    'Invalid login': 'väärä kirjautumistunnus tai salasana',
    'Average Bill': 'Keskimääräinen tulos',
    'Missing credentials': 'Anna kirjautumistunnus ja salasana',
    'New team member created': 'Uusi käyttäjä tallennettu',
    'Team member updated': 'Käyttäjä päivitetty',
    'Team member deleted': 'Käyttäjä poistettu',
    Specialties: 'Erikoisuudet',
    'Static price': 'Kiinteä hinta',
    'Static duration': 'Kiinteä kesto',
    'Allow log in': 'Salli valtuutus',
    'User balance': 'Käyttäjän saldo',
    'Your access has been restricted. Please contact your administrator to resolve this issue.': 'Pääsyäsi on rajoitettu. Ota yhteyttä järjestelmänvalvojaasi.',
    Roles: 'Roolit',
    'Balance top up': 'Saldon täydennys',
    Resource: 'Resurssi',
    Permissions: 'Käyttöoikeudet',
    'Paid partially': 'Ei maksettu kokonaan',
    'Paid': 'Maksettu',
    'New role': 'Uusi rooli',
    View: 'Selaa',
    'Edit role': 'Muokkaa rooli',
    'new': 'Uusi',
    'By payment type': 'Maksutavan mukaan',
    'By team members': 'Ryhmän jäsenten toimesta',
    'By age': 'Iän mukaan',
    'Ex.:Sales manager': 'Esim. Myyntipäällikkö',
    'Ex.:Can approve payments and manage billing': 'Esim. Pystyy hoitamaan rahoitusta ja varastoa',
    'my-team': 'Tiimi',
    'By day': 'Päivien mukaan',
    'By status': 'Statuksen mukaan',
    'Debt and prepaid': 'Velat ja ennakkomaksut',
    'my-company': 'Yhtiö',
    'permissions': 'Oikeudet',
    'Most valuable team member': 'Ryhmän arvokkain jäsen',
    'Least valuable team member': 'Vähiten arvokkain tiimin jäsen',
    'Most valuable patient': 'Arvokkain potilas',
    'Least valuable patient': 'Vähiten arvokas potilas',
    'Most debt patient': 'Potilas, jolla on eniten velkaa',
    'Most prepaid patient': 'Potilas, jolla on korkein ennakkomaksu',
    'Toppers': 'Topit',
    'Most selling product': 'Myydyin tuote',
    'Least selling product': 'Vähiten myyty tuote',
    'Most used product': 'Eniten käytetty tuote',
    'Most used service': 'Eniten käytetty palvelu',
    'Most selling service': 'Myydyin palvelu',
    'Least used product': 'Vähiten käytetty tuote',
    'Least selling service': 'Vähiten myyvä palvelu',
    'Least used service': 'Vähiten käytetty palvelu',
    'roles': 'Roolit',
    'users': 'Käyttäjät',
    'Add new role': 'Lisää rooli',
    'Actions': 'Toiminnot',
    'See all': 'Näytä kaikki',
    'Changes to roles saved': 'Roolimuutokset päivitetty',
    'Appointment created': 'Viesti luotu',
    'Treatment presets': 'Hoitomallit',
    'SMS template': 'SMS-viestimalli',
    'Telegram template': 'Telegram-viestimalli',
    'Explanation of debt': 'Muistinpano',
    'Discount': 'Alennus',
    'Ex.: VIP': 'Esim.: VIP asiakas',
    'Exam on': 'Tarkastus alkaen',
    'Done on': 'Tehty',
    'Per page': 'Per sivu',
    'Pre-treatment status': 'Hampaiden kunto',
    'Teeth and mouth condition': 'Hampaiden ja suuontelon tila',
    'Condition': 'Kunto',
    'Passport number': 'Passin ID',
    'Passport given by': 'Passi myönnetty',
    'Patient passport number': 'Potilaan passin numero',
    'Patient age': 'Potilaan ikä',
    'Patient gender': 'Potilaan sukupuoli',
    'Patient diagnosis': 'Potilaan diagnoosi',
    'Patient address': 'Potilaan osoite',
    'Patient birth date': 'Potilaan syntymäpäivä',
    Earnings: 'Osoittautui',
    Revenue: 'Tulot',
    Payed: 'Maksettu',
    'Payed out': 'Maksettu',
    'Confirm': 'Vahvista',
    Earned: 'Ansaittu',
    'Left over': 'Nettotulo',
    'Doctor first name': 'Lääkärin nimi',
    'Doctor last name': 'Lääkärin sukunimi',
    'Patient passport given by': 'Potilaan passi myönnetty',
    Prepaid: 'Esimaksu',
    Channel: 'Kanava',
    'Transaction ID': 'Tapahtumanro',
    'Tooth does not have any exams yet': 'Hammasta ei ole vielä tutkittu',
    'Start examing tooth by clicking "New exam"': 'Aloita ensimmäinen hammastutkimus heti',
    'Conclusion': 'Päätelmä',
    'Application form': 'Kyselylomake 043',
    Sum: 'Summa',
    Debt: 'Velka',
    'Emergency logout': 'Hätäuloskäynti',
    'Are you sure you want to logout all of your team?': 'Haluatko varmasti kirjata kaikki tiimisi jäsenet ulos?',
    perm: {
        'appointment': 'Tapaaminen',
        'customers': 'Asiakkaat',
        'my-company': 'Minun yhtiö',
        'my-team': 'Minun tiimi',
        'cart': 'Ostakori',
        'company': 'yhtiö',
        'profile': 'Profiili',
        'presets': 'Hoitomalli',
        'payments': 'Maksut',
        'tags': 'Tunnisteet',
        'communications': 'Viestintä asiakkaan kanssa',
        'notes': 'Muistinpano',
        'xrays': 'Röntgenkuvaus',
        'products': 'Tuotteet ja varasto',
        'reports': 'Raportit',
        'notifications': 'Ilmoitukset',
        'roles': 'Roolit ja käyttöoikeudet',
        'treatment': 'Menettelyt',
        'icd': 'ICD-10',
        'odb': 'Objektiivisesti',
        'service': 'Plavelut',
        'timeline': 'Toimintaloki',
        'bundle': 'Asetukset',
        'time-templates': 'Ilmoitusajan mallit',
        'force-logout': 'Hätäkirjautuminen ulos',
        'tasks': 'Tehtävät',
        'work-place': 'Työpaikat',
        'service-reports': 'Palveluraportit',
        'product-reports': 'Tuoteraportit',
        'sales-reports': 'Lääkärin raportit',
        'customer-reports': 'Lääkärin raportit',
        'notification-reports': 'Ilmoitusraportit',
        'configurator': 'Konfiguraattori',
        'treatment-plan': 'Hoitosuunnitelma',
        'media-summary': 'Arkistoi raportit',
        'subscriptions': 'Tilaukset',
        'push-notifications': 'Push ilmoitukset',
        'resources': 'Resurssit',
        'text-templates': 'Tekstimallit',
        'feedback-question': 'Kyselykysymykset',
        'feedback-questionnaire': 'Kyselylomakkeet',
        'feedback-answers': 'Vastaukset kysymyksiin',
        'labs': 'Laboratorio',
        'expense': 'Kulut',
        'toppers': 'Topit',
        'balance-reports': 'Ennakkomaksu-/velkailmoitukset',
        'payment-reports': 'Maksutaparaportit',
        'appointment-reports': 'Lähetä raportit',
        'suppliers': 'Toimittajat',
        'supplies': 'Tarvikkeet',
        'categories': 'Kategoriat',
        'brands': 'Brändit',
        'reports-summary': 'Yleisraportteja',
        'invoices-summary': 'Tiliraportti',
        'sources': 'Lähteet',
        'specialty': 'Erikoisuudet',
        'cdb': 'Valitukset',
        'notification': 'Ilmoitus',
        'my-notifications': 'Ilmotukset',
        'diagnose-objectively': 'Lopullinen diagnoosi',
        'fields': 'Terveyskortti',
        'exam': 'Tarkastus',
        'manipulations': 'Manipulointi',
        'users': 'Пользователи',
        'permissions': 'Käyttöoikeudet',
        'tooth': 'Hampaat',
        'invoices': 'Laskut',
        'transactions': 'Maksut',
        'documents': 'Dokumentit',
        'sms': 'Tekstiviesti',
        'sessions': 'Aktiiviset istunnot',
        'payment-history': 'Maksuhistoriaraportit',
        'expense-reports': 'Kulutusraportit',
        'onlinepbx-incoming-call': 'Online PBX-järjestelmä',
    },
    'Pay another': 'Maksa enemmän',
    'Back': 'Takaisin',
    'Pay': 'Maksaa',
    'Payment': 'Maksu',
    'Pay back': 'Antautua',
    'Ex.: Pain': 'Esim. Kipu',
    'Invoice closed': 'Lasku закрыт',
    'Baby map': 'Lasten kortti',
    'Treatment price': 'Menettelyn hinta',
    'My 32Desk': 'Minun 32Desk',
    'Current and previous illnes': 'Menneet ja siihen liittyvät sairaudet',
    'Profession': 'Ammatti',
    'X-ray examinations history': 'Röntgentutkimusten historia',
    'Document generated by': 'Luotu kanssa',
    'Manipulations made': 'Suoritettu manipulaatioita',
    'Products used': 'Käytettiin',
    'Ministry of Healthcare': 'Terveydenhuollon ministeriö',
    'ProfileFormBy': '',
    'Medical card of dental patient': 'Hammaspotilaan lääkärikortti',
    'Fullname': 'Sukunimi, Etunimi, Toinen nimi',
    'Recommendations made': 'Suosituksia annettu',
    'Recommendations': 'Suositukset',
    'Ex. rinse mouth twice a day': 'Esim. Huuhtele suusi kahdesti päivässä',
    'Initial examination': 'Alkutarkastus',
    'Bite': 'Purra',
    'Overjet': 'Syvä',
    'Open bite': 'Avoin',
    'Crossbite': 'Ylittää',
    'Overbite': 'Distaalinen (prognaattinen)',
    'Underbite': 'Mesiaali (jälkeläinen)',
    'Orthognathic': 'Ortognaattinen',
    'Progenic': 'Progeeninen',
    'Straight': 'Suoraan',
    'Biprognatic': 'Biprognattinen',
    'State of oral mucosa': 'Suun limakalvon tila',
    'X-Ray and Lab examination results': 'Röntgen- ja laboratoriotiedot',
    'Normal': 'Hyvä',
    'Satisfactory': 'Tyydyttävä',
    'Unsatisfactory': 'Epätyydyttävä',
    'Bad': 'Huono',
    'Very Bad': 'Todella paha',
    'Accept appointments': 'Hyväksyy merkinnät',
    'Percent from total revenue': 'Perusprosentti palveluista',
    'By Staff': 'Työntekijöiden toimesta',
    'Scheduled for today': 'Äänitetty tälle päivälle',
    'Oral Hygiene Index': 'Hygieniaindeksi Fedorov-Volodkinan mukaan',
    'Oral Hygiene Index GV': 'Green-Vermillion hygieniaindeksi',
    'Unknown': 'Tuntematon',
    'POS': 'Myyntipiste',
    'POS terminal': 'Myyntiterminaali',
    'Duplicate to tooth': 'Kopioida hampaan',
    'All done': 'Tehty',
    'Apply': 'Käytä',
    'All top': 'Kaikki huippu',
    'All bottom': 'Kaikki alempana',
    'Clear': 'Asia selvä',
    'By tooth': 'Hampaille',
    'Remove': 'Poistaa',
    'Appointment has been rescheduled': 'Merkintä muokattu',
    'Appointment has been created': 'Viesti luotu',
    'Appointment has been saved': 'Merkintä tallennettu',
    'Do you confirm ?': 'Vahvistatko?',
    'By source': 'Lähteen mukaan',
    'Total earned': 'Ansaittu yhteensä',
    'Total debt for end of period': 'Kokonaisvelka kauden lopussa',
    'Debt for period of time': 'Velat tälle ajanjaksolle',
    'No treatment templates': 'Ei hoitomalleja',
    'Try creating first by saving your current exam': 'Tallenna hoitoprotokollat ​​malleihisi, jotta sinun ei tarvitse toistaa vaiheita',
    'In debt': 'В долг',
    'Edit/Reschedule appointment': 'Merkinnän muuttaminen',
    Yes: 'Kyllä',
    No: 'Ei',
    'Name Last Middle name or Phone': 'Etunimi Sukunimi Toinen nimi tai puhelin numero',
    'Phone number protect': 'Puhelinnumeron suojaus',
    'Do not show customer phone numbers if user does not have a seeAll permission': 'Älä näytä asiakkaan puhelinnumeroa, jos käyttäjällä ei ole lupaa',
    'Stock leftover': 'Jäljellä oleva varasto',
    'Initial quantity': 'Alkumäärä',
    'Total revenue': 'Työntekijän voitto',
    'Other ways of examination': 'Lisätutkimuksen tiedot',
    'Objectivly details': 'Fyysisen tutkimuksen tiedot',
    Diary: 'Päiväkirja',
    'Remind after': 'Muistuta kautta',
    'Tasks': 'Tehtävät',
    'Reminder': 'Muistutus',
    'Start date': 'Aloituspäivämäärä',
    'Due date': 'Eräpäivä',
    'Select team member': 'Valitse tiimin jäsen',
    'Select service': 'Valitse palvelu',
    'Remind about a treatment': 'Muistuta hoidosta',
    'Remind about': 'Muistuta noin',
    'End before': 'Lopeta ennen',
    'Start': 'Alkaa',
    'Select a treatment to be repeated': 'Select a treatment to be repeated',
    'Edit/Reschedule task': 'Muokkaa/aikatauluta tehtävää uudelleen',
    'Create appointment': 'Luo tapaaminen',
    'Treatment received': 'Hoito saatu',
    'Upcoming treatment': 'Tuleva hoito',
    'Do not call after 8 p.m.': 'Älä soita klo 20 jälkeen.',
    'Trash': 'Roskakori',
    'New task created': 'Uusi tehtävä luotu',
    'My templates': 'Minun mallit',
    'Recently used': 'Viimeaikaiet',
    'Deleted at': 'Poistettu',
    'Do you confirm': 'Vahvistatko ',
    'This operation is permanent and can not be reversed.': 'Tämä toiminto on pysyvä eikä sitä voi peruuttaa.',
    'Permanently delete': 'Poista pysyvästi',
    'Recover': 'Palauta',
    'Recently deleted': 'Äskettäin poistettu',
    'Successfully recovered': 'Onnistuneesti toipunut',
    'Permanently deleted': 'Poistettu pysyvästi',
    'Item has been added': 'Kohde on lisätty',
    'Duration step': 'Kestovaihe',
    'Calendar step': 'Kalenteri vaihe',
    'Appointment hours step': 'Ajanvaraustuntien vaihe',
    'Communications': 'Viestintä',
    'Incoming': 'Saapuva',
    'Outgoing': 'Lähtevä',
    'Outgoing call': 'Lähtevä puhelu',
    'Incoming call': 'Saapuva puhelu',
    'Write SMS': 'Kirjoita tekstiviesti',
    'Write Email': 'Kirjoita email',
    'Write Telegram Message': 'Kirjoita Telegram-viesti',
    'Payouts': 'Voitto',
    'Changes saved': 'Muutokset tallennettu',
    'Error while saving': 'Virhe tallennettaessa',
    'Invoice prefix': 'Laskun etuliite',
    'Invoice counter': 'Laskulaskuri',
    'View sample': 'Näytä näyte',
    'Status localis': 'Tila localis',
    'Objectivly checks': 'Objektiiviset tarkastukset',
    'Exam has been created': 'Tentti on luotu',
    'Exam has been saved': 'Tentti on tallennettu',
    'Product has been saved': 'Tuote on tallennettu',
    'Product has been created': 'Tuote on luotu',
    'Roots has been saved': 'Juuret on pelastettu',
    'Branch': 'Konttori',
    'Branches': 'Konttorit',
    'Branches can see all customers': 'Konttorit näkevät kaikki asiakkaat',
    'Common customers, team and reports with branches': 'Jaetut asiakkaat, tiimi ja raportit sivukonttoreiden kanssa',
    'Select branch': 'Valitse konttori',
    'Start of the day': 'Päivän alussa',
    'End of the day': 'Päivän loppu',
    'Date of operations': 'Toimien päivämäärä',
    'Do you want to checkout?': 'Haluatko tehdä lakialoitteen?',
    'Product has been added': 'Tuote on lisätty',
    'Clear selection': 'Tyhjennä valinta',
    'Grouped': 'Ryhmitetty',
    'Select tooth': 'Valitse hammas',
    'Workplaces': 'Työpaikat',
    'Workplace': 'Työpaikka',
    'By workplaces': 'Työpaikkojen mukaan',
    'New workplace': 'Uusi työpaikka',
    'Workplace has been saved': 'Työpaikka on tallennettu',
    'Workplace has been removed': 'Työpaikka on poistettu',
    'Teeth not selected': 'Hampaat ei valittu',
    'Work order': 'Työmääräys',
    'Cart has been save': 'Ostoskori on tallennettu',
    'Cart has been removed': 'Ostoskori on poistettu',
    'Ex. Basic kit': 'Esim. Perussarja',
    'No customer was selected': 'Asiakasta ei valittu',
    'Payout': 'Maksu',
    'New Payout': 'Uusi maksu',
    'Expenses': 'Kulut',
    'New expense': 'Uusi kulut',
    'Expense updated': 'Kulu päivitetty',
    'Expense added': 'Kulu lisätty',
    'Send at appointment create': 'Lähetä ajanvarauksella luo',
    'Template successfully deleted': 'Mallin poistaminen onnistui',
    'Successfully saved': 'Tallennettu onnistuneesti',
    'Successfully removed': 'Poistettu onnistuneesti',
    'SMS notification is not enabled': 'Tekstiviesti-ilmoitus ei ole käytössä',
    'Enable': 'Ota käyttöön',
    'Bonus will not change User balance': 'Bonus ei muuta käyttäjän saldoa',
    'Bonus': 'Bonus',
    'PHONE_FORMAT': '+###(##) ###-##-##',
    'Phone format': 'Puhelin numeron muoto',
    'Stage': 'Vaihe',
    'Configurator': 'Konfiguraattori',
    'Select preset': 'Valitse esiasetus',
    'Movability': 'Liikkuvuus',
    'Artificial tooth': 'Keinotekoinen hammas',
    'Tooth missing': 'Hammas puuttuu',
    'Preview': 'Esikatselu',
    'Attending doctor': 'Hoitava lääkäri',
    'Select attending doctor': 'Valitse hoitava lääkäri',
    'Teeth': 'Hampaat',
    'Add to cart': 'Lisää ostoskoriin',
    'Add stage': 'Lisää vaihe',
    'Dental plaque': 'Hammasplakki',
    'Remove stage': 'Poista vaihe',
    'Added to cart': 'Lisätty ostoskoriin',
    'Add tooth': 'Lisää hammas',
    'Group': 'Ryhmä',
    'Add to work order': 'Lisää työjärjestykseen',
    'Save the initial examination': 'Tallenna alkutarkastus',
    'Save treatment plan': 'Tallenna hoitosuunnitelma',
    'Add subsistence': 'Lisää toimeentulo',
    'Responsible': 'Responsible',
    'Fittings': 'Liittimet',
    Weak: "Heikko",
    Moderately: "Kohtalaisen",
    Joined: "Liittyi",
    "Implant cemented": "Implanti sementoitu",
    Single: "Yksittäinen",
    "Screw fixed": "Ruuvi kiinni",
    Reinforced: "Vahvistettu",
    "System name": "Järjestelmän nimi",
    Diameter: "Halkaisija",
    Cemented: "Sementoitu",
    'Kind of work': 'Työn tyyppi',
    "abutment type": "butmentin tyyppi",
    'Paint restoration': 'maalin restaurointi',
    'no': 'ei',
    'weak': 'Heikosti',
    'moderately': 'kohtalaisen',
    'Dissect teeth on the model': 'Korjaa hampaat mallissa',
    'In the missing tooth area': 'Puuttuvan hampaan alueella',
    'Form an ovoid impression on the model': 'Muodosta munamainen vaikutelma malliin',
    'joined': 'Liittynyt',
    'implant cemented': 'Kiinnitetty implanttiin',
    'single': 'Yksittäinen',
    'screw fixed': 'Ruuvi kiinni',
    'screw-fixed': 'Ruuvi kiinni',
    'reinforced': 'Vahvistettu',
    'Implant crowns': 'Kruunut implanteissa',
    'system name': 'Järjestelmän nimi',
    'diameter': 'Halkaisija',
    'cemented': 'Sementoitu',
    'Shoulder': 'Olkapää',
    'Frame': 'Kehys',
    'Design of intermediate part': 'Keskitason suunnittelu',
    'Temporary restoration': 'Väliaikainen kunnostus',
    'Select work': 'Valitse työ',
    'Work selection': 'Työn valinta',
    'Customer selection': 'Potilaan valinta',
    'Next': 'Edelleen',
    'Append image': 'Lisää kuva',
    'New work': 'Uusi työ',
    'Abutment type': 'Abutmentin tyyppi',
    'Reason for cancelling': 'Peruuttamisen syy',
    'Lab prefix': 'Etuliite asuihin',
    'Lab counter': 'Tilauslaskuri',
    'Labs': 'Laboratorio',
    'Fitted': 'Varustettu',
    'Print separately': 'Tulostaa erikseen',
    'Appointments for period': 'Ilmoitukset tälle ajanjaksolle',
    'Appointments created for period': 'Tänä aikana luodut tietueet',
    'Separate by color': 'Erottele värin mukaan',
    'y.o.': ' | {n} vuosi | {n} vuotta | {n} vuotta',
    'Preliminary treatment plan for': 'Alustava hoitosuunnitelma',
    'signature': 'Allekirjoitus',
    'print plan note': 'Olen lukenut hoitosuunnitelman ja hyväksyn sen. Ymmärrän suunnitelman, hoidon laajuuden, mahdolliset komplikaatiot hoidon kaikissa vaiheissa, mahdolliset lisätutkimukset, muutokset hoitoehdoissa, lisämaksu. Sain vastaukset kaikkiin tulevaa hoitoa koskeviin kysymyksiin ja luotan lääkäreiden pätevyyteen. Hinnat ovat voimassa 180 päivää konsultaatiopäivästä.',
    'remark': 'huomautus',
    'full name': 'Koko nimi',
    'Mark as done': 'Merkitse tehdyksi',
    'Generate and save plan': 'Luoda ja tallentaa suunnitelma',
    'Elementary': 'Perus',
    'No variations': 'Ei variaatioita',
    'Treatment plan of': 'Hoitosuunnitelman',
    'For sending': 'Lähettämistä varten',
    Wishes: 'Toiveita',
    'Shape and color': 'Muoto ja väri',
    'Face shape': 'Kasvojen muoto',
    'When there is not enough space': 'Kun tilaa ei ole tarpeeksi',
    'The color of the crowns': 'Kruunujen väri',
    'The color of the stump': 'Kannen väri',
    'The main color of the tooth': 'Hampaan pääväri',
    'The color of the gums': 'Ienien väri',
    Rectangular: 'Rectangular',
    Triangular: 'Kolmion muotoinen',
    Oval: 'Soikea',
    'Grind the antagonist tooth': 'Hio antagonistihammas',
    'Make a metal occlusal surface': 'Tee metallinen puristuspinta',
    'Completion date': 'Valmistumispäivä',
    Vestib: 'Vestib',
    Without: 'Ilman',
    'Print an image?': 'Tulosta kuva?',
    Technician: 'Teknikko',
    'Search technician': 'Hakuteknikko',
    Main: 'Pää',
    Additional: 'Lisätiedot',
    'Spoons': 'Lusikat',
    'Screws': 'Ruuvit',
    'Abutments': 'Abutmentit',
    'Implants': 'Implantit',
    'Transfers': 'Siirrot',
    'Analogues': 'Analogit',
    'Straight abutment': 'Suora tuki',
    'Corner abutment': 'Kulmatuki',
    'Cast abutment': 'Valettu abutmentti',
    'Premill': 'Esimylly',
    'Temporary abutment': 'Väliaikainen abutmentti',
    'Temporary abutment on multiunit': 'Väliaikainen abutmentti moniyksikössä',
    'Cast abutment on multiunit': 'Valettu abutmentti moniyksikköön',
    'Accept': 'Hyväksyä',
    'Add treatment plan': 'Lisää hoitosuunnitelma',
    'Preliminary treatment plan': 'Alustava hoitosuunnitelma',
    'Create entry': 'Luo merkintä',
    'Are you sure you want to accept a job?': 'Oletko varma, että haluat ottaa työn vastaan?',
    'Images': 'Kuvat',
    'Archive': 'Arkisto',
    'Material consumption for the procedure': 'Toimenpiteen materiaalinkulutus',
    'The cost of this material for the procedure': 'Tämän materiaalin kustannukset menettelyä varten',
    'Number of procedures per batch': 'Toimenpiteiden lukumäärä erää kohti',
    'Profit from one procedure': 'Hyöty yhdestä menettelystä',
    'Total profit': 'Koko tuotto',
    'Total profit of sale': 'Myynnin kokonaisvoitto',
    'No results found': 'Ei tuloksia',
    'No options available': 'Ei vaihtoehtoja',
    'No sub-options': 'Ei alivaihtoehtoja',
    'notConfirmed': 'Ei vahvistettu',
    'Last 12 month': 'Viimeiset 12 kuukautta',
    'Your revenue': 'Sinun tulosi',
    'Nothing found': 'Mitään ei löytynyt',
    'Profile has been updated': 'Profiili on päivitetty',
    'Appointments scheduled': 'Tapaamiset sovittu',
    'Appointments created': 'Tapaamiset luotu',
    'conditionCodes': {
        'C': 'C',
        'Pl': 'Pl',
        'D': 'D',
        'P': 'P',
        'Pt': 'Pt',
        'Cr': 'Cr',
        'AT': 'AT',
        'I': 'I',
        'R': 'R',
        'M': 'M',
        'DP': 'DP',
        'TM': 'TM'
    },
    'Last visit': 'Viimeinen vierailu',
    'Ex.: TV Adv.': 'Esim: tv-mainos.',
    'Searching': 'Etsitään',
    'Select a team member to checkout only his treatments': 'Valitse tiimin jäsen tarkistaaksesi vain hänen hoitonsa',
    'Block The Time': 'Estä aika',
    'Your account will expire on {date}. Please contact us.': 'Tili vanhenee {date}, ota meihin yhteyttä +998 97 006 32 32',
    'Your account expired. Please contact us.': "Tili on vanhentunut. Ota yhteyttä +998 97 006 32 32, sähke: @Supp32Desk",
    'Appointments saved': 'Tapaamiset tallennettu',
    'All methods used': 'Kaikki maksutavat käytössä',
    'Passwords do not match': 'Salasanat eivät täsmää',
    'Current password is incorrect.': 'Nykyinen salasana on väärä.',
    'This field is required': 'Tämä kenttä pitää täyttää',
    'Password has been changed': 'Password has been changed',
    'Debt sum': 'Velan summa',
    'Payout type': 'Maksun tyyppi',
    'Office expenditures': 'Toimistokulut',
    'Overview': 'Yleiskatsaus',
    'Change rate': 'Muutosnopeus',
    'Total expenditure': 'Kokonaiskulutus',
    'Authentication timed out': 'Todennus aikakatkaistiin',
    'Show Cancelled': 'Esitys peruttu',
    'Cst': 'Kustannus',
    'Qty': 'Kpl.',
    'Prod': "Prod",
    'Serv': "Palvelu",
    'Print receipt': 'Tulosta kuitti',
    'examDefaults': {
        'objectivly': 'Kasvot ovat symmetriset ja oikeasuhteiset. kasvojen iho ja näkyvät limakalvot ilman näkyviä patologisia muutoksia.',
        'OralMucosa': 'Vaaleanpunainen limakalvon limakalvo, ilman näkyviä patologisia muutoksia'
    },
    'New Update Available': 'Uusi päivitys saatavilla',
    'Click to refresh': 'Napsauta päivittääksesi',
    'treatment-plan': {
        'label-placeholder': 'Esim.: Terapeuttinen hoitosuunnitelma'
    },
    'Document': 'Document',
    'Filters': 'Suodattimet',
    'Waiting List': 'Odotuslista',
    'Task started': 'Työn alla',
    'Show waiting list': 'Näytä jonotuslista',
    'Add to waiting list': 'Lisää odotuslistalle',
    'Remove from waiting list': 'Poista jonotuslistalta',
    'Full Screen': 'Koko näytöllä',
    'phone-field': {
        'required': 'Vaadittu kenttä'
    },
    'customer-field': {
        'required': 'Vaadittu kenttä'
    },
    'invoice': {
        'print-preview': 'Alustava lasku',
        'preview-to': 'Väliaikainen tili',
        'not-paid': 'Ei maksettu',
        'summary': 'Kokonaispisteet'
    },
    'Merge': 'Yhdistää',
    'Oops.. Something Went Wrong..': 'Hups! Jotain meni pieleen',
    'Union': 'Yhdistys',
    'Customers merge message': 'Potilassivujen yhdistäminen on peruuttamaton toimenpide. Kaikki valitun sivun tiedot kopioidaan tälle sivulle. Valittu sivu siirretään "Arkisto"-välilehdelle.',
    'Print templates': 'Tulosta mallit',
    'Treatment plan print page description': 'Tietoinen suostumus hoitosuunnitelmaan',
    // TODO: 'Subscription' from SubscriptionList.vue,
    // TODO: 'Subscription' from SubscriptionList.vue,
    'In development': 'Kehityksessä',
    'Field is empty': 'Kenttä on tyhjä',
    'Recent Calls': 'Viimeaikaiset puhelut',
    'SMS Messages': 'SMS viestit',
    'Email': 'Sähköposti osoite',
    'Telegram': 'Telegram',
    'CTRL+ENTER to send your message': 'CTRL + ENTER lähettääksesi viestin',
    'Type your message': 'Kirjoita viestisi',
    'Total debt': 'Velka yhteensä',
    'Enter phone number': 'Anna puhelinnumero',
    'Send code': 'Lähetä koodi',
    'Telegram code': 'Telegram koodi',
    'Two-factor authentication': 'Kaksivaiheinen todennus',
    'payments': {
        'total-paid': 'Kokonaistulot',
        'total-services': 'Hoidon kustannukset',
        'total-discount': 'Alennuksen määrä',
    },
    'settingsDesc': {
        'services': 'Hallitse palveluita, menettelyjä, niiden hintoja ja kestoa tässä osiossa',
        'team': 'Tiedot työntekijöistä, kalenterin merkintöjen väri, aikataulu, työntekijöiden palkat määritetään täällä',
        'company': 'Osio yrityksen perustamista varten',
        'documents': 'Luo sopimuksia ja muita asiakirjoja, joissa asiakkaiden nimet täytetään automaattisesti jne. tässä',
        'sources': 'Miten asiakas sai tietää yrityksestäsi, voit analysoida mainoskampanjoita',
        'tags': 'Tunnisteiden avulla voit ryhmitellä asiakkaita lisäsuodatusta varten heidän mukaansa sekä "merkitä" asiakkaita',
        'work-places': 'Lisää työpaikkoja, huoneita, toimistoja kätevästi kalenteriin',
        'roles': 'Joustavat pääsyasetukset - luo rooleja ja määritä, mitä työntekijä voi tehdä',
        'user-activities': 'Kaikkien käyttäjien tekemien toimien historia',
        'trash': 'Tärkeät poistetut kohteet päätyvät roskakoriin ennen kuin ne poistetaan pysyvästi',
        'specialties': 'Yrityksesi työntekijöiden erikoisalat',
        'icd10': 'Kansainvälinen sairauksien luokittelu (ICD-10)',
        'complaints': 'Potilasvalitusluettelon avulla voit täyttää avohoitokortit tehokkaasti',
        'objectively': 'Lista "Objektiivisesti..." avohoitokorttien tehokkaaseen täyttämiseen',
        'manipulations': 'Toimenpiteiden aikana tehdyt manipulaatiot mahdollistavat avohoitokorttien automaattisen täyttämisen',
        'presets': 'Hoitomallit ovat kätevä työkalu suoritettujen toimenpiteiden nopeaan korjaamiseen sekä avohoitokorttien automaattiseen täyttämiseen',
        'configurator': 'Oikein konfiguroidun konfiguraattorin avulla voit täyttää hoitosuunnitelman yhdellä napsautuksella',
        'questionnaires': 'Hallinnoi kyselylomakkeita kerätäksesi palautetta asiakkailtasi',
        'textTemplates': 'Voit luoda, muokata ja poistaa uusia tekstimalleja',
        'sessions': 'Aktiiviset istunnot ja laitteet',
    },
    'Telegram password': 'Telegram salasana',
    'payment': {
        'status': {
            'debiting-from-balance': "Veloitus saldosta"
        }
    },
    'communications': {
        'failed': 'Lähettämätön',
        'scheduled': 'Suunniteltu',
        'sent': 'Lähetetty'
    },
    'tasks': {
        'task-type': 'Tehtävän tyyppi',
        'treatment-reminder': 'Hoidon muistutus',
        'user-reminder': 'Muistutus',
        'task-description': 'Tehtävän kuvaus',
        'desc-placeholder': 'Soita kirjanpitäjälle'
    },
    'treatment': {
        'print': 'Tulosta'
    },
    'Scale': 'Mittakaava',
    'loadAppointment': 'Vielä 1 tapaaminen | Vielä {n} tapaamista | Vielä {n} tapaamista | Vielä {n} tapaamista',
    'Show as a list': 'Näytä luettelona',
    'Technican': 'Teknikko',
    'sessions': {
        'sessions': 'Aktiiviset istunnot',
        'last-action': 'Viimeinen toimenpide',
        'user-agent': 'Selain ja laite',
        'login': 'Viimeinen sisäänkäynti',
        'ip': 'IP-osoite'
    },
    'filters': {
        'by-completion-date': 'Toimituspäivän mukaan',
    },
    'Could not fetch data': 'Tietojen hakeminen epäonnistui',
    'Try again': 'Yritä uudelleen',
    'Dental chart': 'hampaiden kaava',
    'For example: American, FDI': 'Esim.: Amerikkalainen, WHO',
    'FDI': 'WHO',
    'USA': 'Amerikkalainen',
    'New initial exam layout': 'Uudenlainen perustarkastus',
    'Use new initial exam layout': 'Käytä uudentyyppistä perustarkastusta',
    'Text Templates': 'Tekstimallit',
    'Title of template': 'Mallin nimi',
    'Content of template': 'Mallin sisältö',
    'Item has been removed': 'Merkintä poistettu',
    'English': 'Englanti',
    'Russian': 'Venäjä',
    'Add Description': 'Lisää kuvaus',
    'Describe treatment': 'Kuvaa hoitoa',
    'x-ray': {
        'ct_up_low_jaw': 'CT i/n/h',
        'ct_up_low_tmj_jaw': 'CT-skannaus in/n/h ja TMJ-nivelet',
        'ct_up_ms_jaw': 'TT-skannaus ja poskiontelot',
        'tgr_lateral_projection': 'TRG lateraalisessa projektiossa',
        'tgr_front_projection': 'TRG suorassa projektiossa',
        'tgr_subchinoparietal_projection': 'TRG SMV-projektiossa',
        'section4_tmj': '4-osainen kuva TMJ:stä',
        'section6_tmj': '6-osainen kuva TMJ:stä'
    },
    'Your session': 'Sinun istuntosi',
    'Online appointments': 'Online-tapaamiset',
    'Appointment booking': 'Ajanvaraus',
    'Online appointment booking': 'Online-ajanvaraus',
    'Allows your clients to record themselves using a step-by-step form': 'Antaa asiakkaasi tallentaa itsensä vaiheittaisella lomakkeella',
    'Skip doctor select': 'Ohita lääkärin valinta',
    'Disallows your clients to select the doctor': 'Estää asiakkaitasi valitsemasta lääkäriä',
    'Skip time select': 'Ohita aika valitse',
    'Disallows your clients to select the time for appointment': 'Estää asiakkaitasi valitsemasta tapaamisaikaa',
    'Skip date select': 'Ohita päivämäärän valinta',
    'Disallows your clients to select the date for appointment': 'Estää asiakkaitasi valitsemasta tapaamispäivää',
    'Show online appointments': 'Näytä online-tapaamiset',
    'Drafts': 'Luonnokset',
    'Mobile payments': 'Mobiilimaksut',
    'Created by': 'Käyttäjän luoma',
    'Treatment select while scheduling an appointment in the calendar': 'Menettelyn valitseminen kalenterista merkintää luotaessa',
    'Enabled: treatment selection is required, and it will be included in the invoice': 'Päällä: kalenterissa on valittava menettely merkintää luotaessa ja se lisätään laskuun',
    'Disabled: treatment and service are not required, and they will be included in the invoice': 'Pois: palvelua ja toimenpidettä ei voi valita kalenterista, eikä niitä lisätä laskuun',
    'Values': 'Arvot',
    'Show If:': 'Näytä jos:',
    'Is equal to:': 'On yhtä suuri kuin:',
    'Use in profile card:': 'Käytä profiilikortissa:',
    'Custom field': 'Mukautettu kenttä',
    'Subscribe Push Notification': 'Tilaa Push-ilmoitus',
    'Subscription': 'Tilaus',
    'No subscription yet': 'Ei vielä tilausta',
    'Current subscription': 'Nykyinen tilaus',
    'Created at': 'Luotu',
    'Patient health card': 'Potilaan terveyskortti',
    'The information you provide will allow the doctor to choose effective anesthesia, medications and methods of treatment, and correctly draw up a treatment plan. The doctor will explain (in justified cases): whether there is a connection between your general health and the state of the oral cavity.': 'Antamiesi tietojen avulla lääkäri voi valita tehokkaan anestesian, lääkkeet ja hoitomenetelmät sekä laatia oikean hoitosuunnitelman. Lääkäri selittää (perustelluissa tapauksissa): onko yleisterveytesi ja suuontelon tilan välillä yhteyttä.',
    'Your data is a medical secret and will not be available to unauthorized persons.': 'Tietosi ovat lääketieteellisiä salaisuuksia, eivätkä ne ole luvattomien henkilöiden saatavilla.',
    'Last visit to a hospital or clinic (month, year):': 'Viimeisin käynti sairaalassa tai klinikalla (kuukausi, vuosi):',
    'What was the appeal about?': 'Mistä valituksessa oli kyse?',
    'Are you registered in a psycho-neurological, narcological, dermatological and venereal dispensary?': 'Oletko rekisteröitynyt psyko-neurologiseen, narkologiseen, dermatologiseen ja sukupuolihoitoon?',
    'If yes, in what and from what time?': 'Jos kyllä, missä ja mistä lähtien?',
    'In addition, I want to inform you about the state of my health the following:': 'Lisäksi haluan kertoa terveydentilastani seuraavaa:',
    'Date of visit': 'Vierailun päivämäärä',
    'Telegram authentication': 'Telegram-todennus',
    'Enter the phone number to enter the Telegram account from which notifications about recordings will be sent to customers': 'Anna puhelinnumero päästäksesi Telegram-tiliin, josta lähetetään ilmoituksia tallennuksista asiakkaille',
    'Integrations': 'Integraatiot',
    'Soon': 'Pian',
    'Size': 'Koko',
    'Total used': 'Käytetty yhteensä',
    'Now': 'Nyt',
    'When scheduled': 'Ajastettuna',
    'Ex.: Pain while eating': 'Esim.: Kipu syömisen aikana',
    'Already exists': 'On jo olemassa',
    'Scheduled': 'Aikataulutettu',
    'Do not notify': 'Älä ilmoita',
    'Add Notes': 'Lisätä huomautus',
    'Select country': 'Valitse maa',
    'For example: USA, Finland, etc': 'Esimerkiksi: USA, Suomi jne',
    'Wrong API key': 'Väärä API-avain',
    'OnlinePBX API key': 'OnlinePBX API-avain',
    'OnlinePBX domain': 'OnlinePBX-verkkotunnus',
    'OnlinePBX VoIP service integration': 'OnlinePBX VoIP-palvelun integrointi',
    'Integration saved successfully': 'Integrointi tallennettu onnistuneesti',
    'Logged out successfully': 'Uloskirjautuminen onnistui',
    'Domain should end with .onpbx.ru': 'Verkkotunnuksen lopussa tulee olla .onpbx.ru',
    'OnlinePBX is not configured': 'OnlinePBX:ää ei ole määritetty',
    'Waiting clinic to accept the call': 'Klinikka odottaa puhelun hyväksymistä',
    'Clinic has not accepted call': 'Klinikka ei ole ottanut puhelua vastaan',
    'Call is started': 'Puhelu on aktiivinen',
    'Call': 'Puhelu',
    'Current date': 'Nykyinen päivämäärä',
    'Added successfully': 'Lisätty onnistuneesti',
    'Customers with uncompleted work order': 'Asiakkaat, joilla on keskeneräinen työtilaus',
    'No records for this call': 'Ei tietueita tälle puhelulle',
    'account_expired': "Tilisi on vanhentunut. Ota yhteyttä tukitiimiin <a href='https://32desk.com'>32desk.com</a>",
    'Show teeth in invoice': 'Näytä hampaat laskussa',
    'Show \'Tooth\' column in invoice': 'Näytä \'Hammas\' sarake laskussa',
    'Edit treatment data after work order': 'Kyselylomakkeiden täyttäminen',
    'Show additional page on work order to edit done treatments\' data': 'Näytä lisäsivu kyselylomakkeen täyttämistä varten',
    'Legend': 'Yleissopimukset',
    'DP - dental plaque': 'DP - hammasplakki',
    'M - movability': 'M - liikkuvuus',
    'R - root': 'R - juuri',
    'C - caries': 'C - karies',
    'AT - artificial tooth': 'AT - keinotekoinen hammas',
    'Cr - crown': 'Cr - kruunu',
    'Pt - periodontitis': 'Pt - parodontiitti',
    'P - pulpitis': 'P - pulpitis',
    'D - depulped': 'D - tyhjennetty',
    'Pl - plombed': 'Pl - tiiviste',
    'I - implant': 'I - istuttaa',
    'Customer is archived': 'Asiakas on arkistoitu',
    'Customer is updated': 'Asiakas on päivitetty',
    'Customer is registered': 'Asiakas on rekisteröitynyt',
    'Note has been deleted': 'Huomautus on poistettu',
    'Print options': 'Tulostusasetukset',
    'Hide teeth map': 'Piilota hampaiden kartta',
    'Hide remark': 'Piilota huomautus',
    'currentStep': 'Vaihe {currentStep}/{totalSteps}',
    'Set account password': 'Aseta tilin salasana',
    'Treatment templates': 'Hoitomallit',
    'Complains / Objectives': 'Valittaa / Objektiivisesti',
    'Import templates': 'Tuo mallit',
    'Setup finished': 'Asennus valmis',
    'Size of teeth map': 'Hampaiden koko kartta',
    'Adult map': 'Aikuisten kartta',
    'Add section': 'Lisää lohko',
    'Ex.: Registration questionnaire': 'Esim.: Rekisteröintikysely',
    'Show in health card': 'Näytä terveyskortissa',
    'Title of section': 'Osion otsikko',
    'email.subject': 'Viesti yhtiöltä {company}',
    'Treatment has been deleted': 'Hoito on poistettu',
    'Manipulation was removed': 'Manipulaatio poistettiin',
    'Service was removed': 'Palvelu poistettiin',
    'Product was removed': 'Tuote poistettiin',
    'Changed': 'Muutettiin',
    'Interest': 'Tarjous',
    'Code will be used in profile card of customer': 'Koodia käytetään asiakkaan profiilikortissa',
    "The description of the treatment in the patient's medical record is not editable": "Hoidon kuvaus potilaan sairauskertomuksessa ei ole muokattavissa",
    'The order includes an additional treatment description page for the patient\'s medical record \'Next\'. The outfit will be considered incomplete if you do not go to this additional page.': "Tilaukseen sisältyy ylimääräinen hoitokuvaussivu potilaan sairauskertomukseen \'Seuraava\'. Asu katsotaan keskeneräiseksi, jos et mene tälle lisäsivulle.",
    'Top ups': 'Saldon täydentäminen',
    'Date constraint is required': 'Päivämääräsuodatin vaaditaan',
    'Deleted customer': 'Poistettu asiakas',
    'Service language': 'Palvelun kieli',
    'Default service language': 'Palvelun oletuskieli',
    'Default service language for new customer': 'Oletuspalvelukieli uudelle asiakkaalle',
    'Debt payment': 'Velan maksu',
    'Language & Region': 'Kieli ja Alue',
    'My earnings': 'Minun tuloni',
    'Last withdrawal': 'Viimeinen nosto',
    'Code has been sent to the number:': 'Koodi on lähetetty numeroon:',
    'Change number': 'Vaihda numero',
    'Logged in': 'Kirjautunut sisään',
    'Help': 'Auta',
    'Notification templates': 'Ilmoitusmallit',
    'Service language allows you to send notifications in the client\'s language': 'Palvelun kielellä voit lähettää ilmoituksia asiakkaan kielellä',
    'Service languages': 'Palvelukielet',
    'Add a new service language': 'Lisää uusi palvelukieli',
    'Default': 'Oletus',
    'Language added': 'Kieli lisätty',
    'Language removed': 'Kieli poistettu',
    'New default language selected': 'Uusi oletuskieli valittu',
    'VAT': 'ALV',
    'incl. VAT': 'sis. ALV',
    'with VAT': 'ALV kanssa',
    'The total price above includes VAT': 'Yllä oleva kokonaishinta sisältää arvonlisäveron',
    'Prices include VAT': 'Hinnat sisältävät arvonlisäveron',
    'Questionnaires': 'Kyselylomakkeet',
    'questionnaire': {
        'questions_count': 'Kysymyksiä: {n}',
    },
    'questionnaire-question': {
        'types': {
            'text': 'Teksti',
            'single-choice': 'Yksittäinen valinta',
            'multiple-choice': 'Monivalinta',
        }
    },
    'No options yet. Press + to add': 'Ei vielä vaihtoehtoja. Lisää painamalla +',
    'Option': 'Vaihtoehto',
    'Option. Ex. Satisfied': 'Vaihtoehto. Esim. Tyytyväinen',
    'New question': 'Uusi kysymys',
    'Edit question': 'Muokkaa kysymystä',
    'New questionnaire': 'Uusi kyselylomake',
    'Edit questionnaire': 'Muokkaa kyselylomaketta',
    'Questions count': 'Kysymykset ovat tärkeitä',
    "Question has been created": 'Kysymys on luotu',
    'Question has been saved': 'Kysymys on tallennettu',
    'Question deleted successfully': 'Kysymys poistettu onnistuneesti',
    'Questionnaire has been created': 'Kyselylomake on luotu',
    'Questionnaire has been saved': 'Kyselylomake on tallennettu',
    'Questionnaire has been removed': 'Kyselylomake on poistettu',
    'No questions yet': 'Ei kysymyksiä vielä',
    'Question': 'Kysymys',
    'Ex.: Rate the service': 'Esim.: Arvioi palvelumme',
    'Answer type': 'Vastaustyyppi',
    'Select type': 'Valitse tyyppi',
    'Send survey link': 'Lähetä linkki kyselyyn',
    'Copied to clipboard!': 'Kopioitu leikepöydälle!',
    'Surveys': 'Kyselyt',
    'Answers': 'Vastaukset',
    'answers': 'vastauksia',
    'questions': 'kysymyksiä',
    'No questionnaires': 'Ei kyselylomakkeita',
    'Ex.: Service rate survey': 'Esim.: Palvelutasotutkimus',
    'To': 'Kenelle',
    'Not paid': 'Ei maksutettu',
    'Debt due date': 'Velan eräpäivä',
    'Appointment rescheduled notifications': 'Ajanvarausilmoitukset',
    'Notification about reschedule of the appointment': 'Ilmoitus ajan siirrosta',
    'Appointment rescheduled notification template': 'Tapaamisen uudelleenaikataulun ilmoitusmalli',
    'Total received': 'Yhteensä saapuneet',
    'Notification channels': 'Ilmoituskanavat',
    'Default notification channels': 'Oletusilmoituskanavat',
    'These channels will be enabled for new customers': 'Nämä kanavat otetaan käyttöön uusille asiakkaille',
    'WhatsApp integration': 'WhatsApp-integraatio',
    'Phone number ID': 'Puhelinnumeron tunnus',
    'Access token': 'Pääsyavain',
    'WhatsApp webhook setup params:': 'Webhook-asetukset WhatsAppiin:',
    'Verify Token': 'Vahvista-avaimen',
    'Whatsapp integration updated': 'WhatsApp-integraatio päivitetty',
    'Webhook URL': 'Webhookin URL',
    'Whatsapp integration cleared': 'WhatsApp-integraatio poistettu',
    'Enter Whatsapp template name': 'Anna WhatsApp-mallinnimen',
    'Link for Online booking': 'Online-varauksen linkki',
    'Enter account data': 'Syötä tilitiedot',
    'Confirm your email': 'Vahvista sähköpostiosoitteesi',
    'Error': 'Virhe',
    'Error occurred. Try again later': 'Tapahtui virhe. Yritä myöhemmin uudelleen',
    'Email is already registered.': 'Sähköposti on jo rekisteröity.',
    'Company name is required': 'Yrityksen nimi vaaditaan',
    'Phone is required': 'Puhelin vaaditaan',
    'Invalid email': 'Virheellinen sähköpostiosoite',
    'Email is required': 'Sähköposti vaaditaan',
    'Last name is required': 'Sukunimi vaaditaan',
    'First name is required': 'Etunimi vaaditaan',
    'We sent a link to verify your email': 'Lähetimme linkin vahvistamaan sähköpostiosoitteesi',
    'Account created. You can login now': 'Tili luotu. Voit kirjautua sisään nyt',
    'Scheduled to': 'Varattu',
    'Reset link sent to your email': 'Nollauslinkki lähetetty sähköpostiisi',
    'Reset Password': 'Palauta salasana',
    'Enter your email to get a password reset link': 'Kirjoita sähköpostiosoitteesi saadaksesi salasanan palautuslinkin',
    'Password reset': 'Salasanan palautus',
    'Enter new password': 'Syötä uusi salasana',
    'Password successfully changed': 'Salasanan vaihto onnistui',
    'Remember your password?': 'Muista salasanasi?',
    'Terminate other sessions': 'Lopeta muut kirjautumisen jälkeen',
    'Logs out all devices except for this one': 'Kirjaa ulos kaikista laitteista paitsi tätä',
    'Terminate all sessions, except current one?': 'Lopetetaanko kaikki istunnot, paitsi nykyinen?',
    'Sign in with Google': 'Kirjaudu sisään Googlen kautta',
    'google-auth.account-not-found-message': 'Tiliä osoitteella {email} ei löytynyt. Haluatko <a class="alert-link text-decoration-underline" href="/welcome">luoda uuden tilin</a>?',
    'Share calendar': 'Jakaa kalenteri',
    'Invoices': 'Laskut',
};
