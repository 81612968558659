// noinspection JSNonASCIINames,NonAsciiCharacters
export default {
    Planned: "Запланировано",
    Arrived: "Прибыл",
    arrived: "Прибыл",
    Appointments: "Записи",
    Appointment: 'Запись',
    Board: "Доска",
    List: "Список",
    Materials: "Материалы",
    Store: "Склад",
    Calendar: "Календарь",
    Customers: "Клиенты",
    customers: 'Клиенты',
    Patients: "Клиенты",
    Patient: "Клиенты",
    Customer: "Клиент",
    Custom: "Пользовательский",
    Products: "Продукты",
    Product: "Продукт",
    Categories: "Категории",
    Category: "Категория",
    'Category name': 'Название категории',
    'Ex. Consumables': 'Расходники',
    Brands: "Бренды",
    Brand: "Бренд",
    Suppliers: "Поставщики",
    Supplier: "Поставщик",
    Subject: 'Тема',
    Body: 'Сообщение',
    Company: "Компания",
    'Appointment time': 'Время встречи',
    'Appointment date': 'Дата встречи',
    'Make default': 'Сделать по умолчанию',
    'Appointment notification template': 'Шаблон уведомлений о встрече',
    'Debt return notification template': 'Шаблон уведомлений должников',
    Services: "Услуги",
    'Services & prices': 'Услуги и прайс',
    Profile: "Профиль",
    profile: "Профиль",
    Team: "Команда",
    "ICD-10": "МКБ-10",
    Reports: "Отчеты",
    Summary: "Общие",
    Payments: "Оплаты",
    People: "Люди",
    'New customer': "Новый клиент",
    'New appointment': "Новая запись",
    Confirmed: "Подтвержден",
    Late: "Опоздал",
    'Not confirmed': "Не подтвержден",
    confirmed: "Подтвержден",
    Completed: "Завершен",
    completed: "Завершен",
    Complete: "Завершить",
    'No show': "Неявился",
    'noshow': "Неявился",
    Today: 'Сегодня',
    Tomorrow: 'Завтра',
    'Next week': 'След. неделя',
    'Next month': 'След. месяц',
    Yesterday: 'Вчера',
    'This week': 'На этой неделе',
    'This month': 'В этом месяце',
    'Previous week': "Прошедшая неделя",
    'Previous month': "Прошлый месяц",
    'Custom range': "Указать период",
    'By date': "По дате",
    'Team member': "Сотрудник",
    'By guarantee': 'По гарантии',
    guarantee: 'Гарантия',
    Guarantee: 'Гарантия',
    cash: "Наличные",
    credit: "В долг",
    card: "По карте",
    userBalance: 'Баланс клиента',
    mobilePayment: 'Мобильные платежи',
    Presets: 'Шаблоны',
    Preset: 'Шаблон',
    'New balance': 'Баланс после',
    'Debt info': 'Возврат долга',
    'Preset has been updated': 'Шаблон сохранен',
    'Preset has been created': 'Шаблон добавлен',
    bank: "Банковский п-д",
    Address: "Адрес",
    "First name": "Имя",
    "Last name": "Фамилия",
    "Patient first name": "Имя клиента",
    "Patient last name": "Фамилия Клиента",
    Details: "Детали",
    General: "Основные",
    Gender: "Пол",
    Male: "Мужчина",
    Female: "Женщина",
    male: "Мужчина",
    female: "Женщина",
    More: "Дополнительно",
    Birthdate: "Дата рождения",
    Phone: 'Телефон',
    Complaints: 'Жалобы',
    Complaint: 'Жалоба',
    Objectively: "Объективно",
    Exam: "Осмотр",
    Exams: "Осмотры",
    'New exam': "Новый осмотр",
    'Customer card': 'Карточка клиента',
    'All exams history will be here': 'Вся история осмотров храниться в этом разделе',
    'Date created': 'Добавлен',
    'Customer passport': "Паспорт клиента",
    'Nothing yet': 'Пока ничего',
    Code: 'Код',
    Shortcode: 'Краткий  код',
    Description: 'Описание',
    Language: 'Язык',
    Label: "Наименование",
    manage: "Управление",
    'Complaints DB': 'Справочник жалоб',
    'Complaint has been saved': 'Жалоба сохранена',
    'Complaint has been created': 'Жалоба добавлена',
    'Objectively DB': 'Справочник объективно',
    References: "Справочники",
    Edit: "Редактировать",
    Timeline: "История",
    Notes: "Заметки",
    Billing: "Оплаты",
    Storage: "Файлы",
    Search: "Поиск",
    Name: "Имя",
    Diagnosis: "Диагноз",
    Diagnoses: "Диагнозы",
    Treatment: "Лечение",
    Consumables: 'Расходники',
    Service: 'Услуга',
    Price: 'Цена',
    'Retail price': 'Розничная цена',
    'Retail price per': 'Розничная цена за',
    'Duration (Min)': 'Длительность (мин)',
    'Buffer time (Min)': 'Буферное время',
    'Ex.: A001': 'Напр.: A001',
    'Treatment name': 'Название процедуры',
    'Treatment duration': 'Длительность процедуры',
    'Time required to prepare to the next treatment': 'Время для подготовки к следующей процедуре',
    'Ex.: Primary consultation': 'Напр.: Первичная консультация специалиста',
    'Short description of treatment': 'Краткое описание процедуры',
    'Public treatment': "Отображать в календаре",
    'Public treatments will be published': "Процедура будет отображена у сотрудников, которые её проводят",
    'Services & treatments': 'Услуги и процедуры',
    'Loading...': 'Загрузка...',
    Create: 'Создать',
    Duration: 'Длительность',
    'New treatment': 'Новая процедура',
    min: 'мин.',
    minutes: 'мин.',
    h: 'ч.',
    Upcoming: 'Запланировано',
    Previous: 'Прошедшие',
    All: 'Все',
    New: 'Новый',
    cancelled: 'Отменен',
    Started: 'Начат',
    started: 'Начат',
    'Total scheduled': 'Всего визитов',
    'Start at': 'Начало в',
    'Appointment at': 'Запись на',
    Status: 'Статус',
    Staff: 'Сотрудник',
    Active: 'Активный',
    Archived: 'Архив',
    Age: 'Возраст',
    Image: 'Фото',
    Date: 'Дата',
    'No confirmed appointments': 'Нет подтвержденных записей',
    'No waiting appointments': 'Нет ожидающих записей',
    'No waiting online appointments': 'Нет ожидающих онлайн записей',
    'No started appointments': 'Нет начатых записей ',
    'In treatment': 'В процедуре',
    'Drag and drop to upload content!': 'Перетащите чтобы загрузить ',
    '...or click to select a file from your computer': '... или нажмите, чтобы найти на компьютере',
    '...or press CTRL + V to paste from clipboard': '... или нажмите CTRL + V, чтобы вставить из буфера',
    'Ex.:John': 'Прим.:Валентин',
    'Ex.:Doe': 'Прим.: Иванов',
    'Select date': 'Выбрать дату',
    'Set photo': 'Указать фото',
    'Remove image': 'Удалить фото',
    'Blacklist customer': 'В черный список',
    Blacklisted: 'В черном списке',
    'Blacklisted users can not make new appointment. But administrator still can make an appointment.': 'Добавьте в черный список сомнительных или клиентов часто пропускающих процедуры',
    'Enable general SMS notifications': 'Включить общие СМС уведомления',
    'If active, the customer agreed to receive general SMS messages': 'Клиент будет получать уведомления о записях',
    'Enable promo SMS notifications': 'Включить промо нотофикации',
    'If active, the customer agreed to receive promo SMS messages': 'Клиент будет получать сообщения о промо акциях и других событиях',
    Add: 'Добавить',
    'Add supply': 'Добавить поставку',
    'Add product': 'Добавить продукт',
    'Add category': 'Добавить категорию',
    'Add brand': 'Добавить бренд',
    'Add supplier': 'Добавить поставщика',
    'Ex.: Caries': 'Прим.: Кариес',
    Select: 'Выбрать',
    'Select diagnosis': 'Выбрать диагноз',
    'Preliminary diagnosis': 'Предварительный диагноз',
    'Final diagnosis': 'Окончательный диагноз',
    'Select from templates': 'Выбрать из шаблонов',
    'Save as template': 'Сохранить как шаблон',
    Use: 'Использовать',
    'Service name': 'Название сервиса',
    'Ex.: Consultation': 'Прим.: Консультация',
    'Describe service': 'Опишите сервис',
    'Public section': 'Общедоступный сервис',
    'Public sections will be published.': 'Общедоступные сервисы будут опубликованы на сайте и в приложениях',
    Rank: 'Ранк',
    'Ex.: 0-10': 'Прим.:0-10',
    Visits: 'Посещения',
    'No shows': 'Неявок',
    Options: 'Опции',
    Objectivly: 'Объективно',
    'Are you sure?': 'Вы уверены?',
    'Do you really want to delete the record ?': 'Вы подтверждаете удаление записи?',
    Specialty: 'Специальность',
    'Ex.: Therapist': 'Прим.: Терапевт',
    'Ex.: therapist': 'Прим.: therapist',
    Map: 'Карта',
    Missing: 'Отсутсвует',
    Root: 'Корень',
    Caries: 'Кариес',
    Pulpit: 'Пульпит',
    Periodontit: 'Периодонтит',
    Plombed: 'Пломба',
    Parodontoz: 'Пародонтоз',
    Shakiness: 'Подвижность',
    Crown: 'Коронка',
    Implant: 'Имплант',
    'Ortopedical status': 'Ортопедический',
    'Therapeutic status': 'Терапевтический',
    'Anchor pin': 'Анкерный штифт',
    Vinir: 'Винир',
    Retreat: 'Перелечить',
    Exclude: 'Удалить',
    Inset: 'Вкладка',
    Facet: 'Фасетка',
    'Parodontal status': 'Состояние пародонта',
    'Treatment required': 'Нуждается в лечении',
    'Treatment not required': 'Не нуждается в лечении',
    'Ex. Apple': 'Прим. Apple',
    Bundles: 'Пакеты',
    Bundle: 'Пакет',
    'Exam created': 'Новое обследование',

    Featured: 'В избранные',
    'Treatment plan': 'План лечения',
    'Select treatment': 'Выбрать лечение',
    'Add treatment': 'Добавить лечение',
    'Tooth #': 'Зуб #',
    'Total': 'Всего',
    Channels: 'Каналы',
    'Time': 'Время',
    'mSv': 'мкЗв',
    'No channel measurement': 'Каналы зубов еще не измерялись',
    'Includes services or products': 'Включает сервисы или продукты',
    'Print': 'Печать',
    Contract: 'Договор',
    Others: 'Другие',
    Agreement: 'Соглашение',
    'Add item': 'Дополнительные пункты',
    'No slots': 'Нет слотов',
    Dates: 'Даты',
    Intraoral: 'Интраоральный',
    intraoral: 'Интраоральный',
    Orthopantomogram: 'Ортопантомограмма',
    orthopantomogram: 'Ортопантомограмма',
    'CT scan': 'КТ',
    'cT scan': 'КТ',

    'Client application': 'TR-Client application',
    'Company EIN': 'Код по ОКПО',
    'Add service': 'Добавить сервис',
    'Add another appointment': 'Добавить новую дату',
    'service time': 'сервисное время',
    Xrays: 'Рентген',
    Xray: 'Рентген',
    'New xray': 'Новый рентген',
    Dose: 'Доза',
    Quantity: 'Количество',
    Type: 'Тип',
    'Record created': 'Запись добавлена',
    'Record updated': 'Запись обновлена',
    'Xray created': 'Добавлен рентген',
    'Invoice created': 'Выставлен инвойс',
    'Ex.: Lc': 'Напр.: Lc',
    'Health card': 'Карта здоровья',
    Stock: 'Склад',
    Supply: 'Поставка',
    Supplies: 'Поставки',
    Pricing: 'Цены',
    'Supply price': 'Цена поставки',
    'Enable product pricing': 'Управление ценой',
    'Enable stock management': 'Управление складом',
    'Enable supply management': 'Управление поставками',
    'Product name': 'Наименование продукта',
    'Ex.:Medical glove': 'Напр.:Перчатки медицинские',
    'Barcode': 'Баркод',
    'SKU': 'Артикул',
    'Ex.:12345678': 'Напр.:12345678',
    'Ex.:AC12345': 'Напр.:AC12345',
    'Supply date': 'Дата поставки',
    'Expire date': 'Годен до',
    'Use current supply': 'Расходовать из этой поставки',
    'Supply quantity': 'Кол-во в поставке',
    'The supply will be forced to use in checkout and reports': 'Поставка будет использована как текущая, расходы будут списаны из этой поставки.',
    'Required minimum at store': 'Минимальное кол-во',
    'Measurement': 'Ед. Измерения',
    'Supplier details': 'Детали поставщика',
    'Supplier name': 'Имя поставщика',
    'Ex. Main supplier': 'Напр.:Основной поставщик',
    'Supplier notes': 'Заметки',
    'Ex.: Call after 10 AM': 'Напр.:Звонить после 10 утра',
    'Contacts': 'Контакты',
    'Contact name': 'Контактное лицо',
    'Ex. John Park': 'Напр.: Андрей Дибров',
    'Primary phone': 'Основной номер',
    'Secondary phone': 'Дополнительный',
    'Security': "Безопасность",
    'Schedule': "График",
    'Website': 'Вебсайт',
    'Street': 'Улица',
    'City': 'Город',
    'State': 'Район',
    'ZIP / Post code': 'Почтовый индекс',
    'Country': 'Страна',
    'Ex.: Tashkent': 'Напр. Ташкент',
    'Billing details': 'Данные биллинга',
    'Legal information': 'Юридические данные',
    'Legal information provided in this section will be used for all your invoices and other legal documents': 'Данные указанные в этом разделе будут использованы в юридических документах  а также счетах',
    'Legal name': 'Юридическое имя',
    'Enter notes for all invoices': 'Заметка для инвойсов',
    'Company timezone': 'Временная зона',
    'It is important to select correct timezone.': 'Важно выбрать правильную временную зону',
    'Interface default language': 'Язык интерфейса по умолчанию ',
    'Users can change in their profile.': 'Пользователи системы могут сменить в настройках',
    'Currency': 'Валюта',
    'For all services calculations': 'Используется для всех  рассчетов',
    'Contact information': 'Контактная информация',
    'Social networks': 'Социальные сети',

    'Ml': 'Мл.',
    'ml': 'мл.',
    'Mg': 'Мг.',
    'mg': 'мг.',
    'Sm': 'См.',
    'Units': 'Шт.',
    'units': 'шт.',
    'Pack': 'Упаковка',
    'pack': 'Уп.',
    'Ex.:100': 'Напр.:100',
    'Minimum': 'Минимум',
    'Per serving': 'Порция',
    'To buy': 'Закуп',
    'product': {
        'lowStock': '<b>{product.label}</b> заканчивается на складе'
    },
    'No new notifications': 'Нет новых уведомлений',
    'In waiting list': 'В лист ожидания',
    'Waitlist': 'Лист ожидания',
    'In buy list': 'В списке покупок',
    'Ex.: Agreement': 'Прим.:Договор',
    'Documents': 'Документы',
    'Title': 'Заголовок',
    'Referer': 'Пришел от:',
    'Requires value': 'Требуется значение',
    'Media': 'Фото',
    'Media manager': 'Управление медиа',
    'Treatments': 'Лечение',
    'Take a photo': 'Сделать фото',
    'Select Device': 'Выбрать устройство',
    'Logout': 'Выход',
    'Leave a comment': 'Оставить заметку',
    'Save note': "Сохранить заметку",
    'Password': 'Пароль',
    'Current password': 'Текущий пароль',
    'New password': 'Новый пароль',
    'Confirm new password': 'Подвердите пароль',
    'Update password': 'Обновить пароль',
    'Password suggestions': 'Предложения по паролю',
    'To create a new strong password, you have to meet all of the following requirements:': 'Безопасный пароль должен соответсвовать следующим критериям:',
    'Minimum 8 character': 'Минимум 8 символов',
    'At least one special character (#%.,)': 'Минимум один спец символ  (#%.,)',
    'At least one number': 'Хотябы одна цифра',
    'Can’t be the same as a previous password': 'Не использовался ранее',
    'Update schedule': 'Обновить расписание',
    'Search client': "Поиск клиента",
    'Appointment schedule': 'Новая запись',
    'After {value} times': 'После {value}х раз',
    Repeats: 'Повторяется',
    'Every {value} days': 'Каждые {value} дня',
    'Every 5 days': 'Каждые 5 дней',
    Weekly: 'Раз в неделю',
    'Every {value} weeks': 'Каждые {value}  недели',
    Monthly: 'Раз в месяц',
    'Every {value} months': 'Каждые {value} месяцев',
    'Every 2 months': 'Каждые 2 месяца',
    'Every 3 months': 'Каждые 3 месяца',
    'Every 4 months': 'Каждые 4 месяца',
    'Month': 'Месяц',
    'Payment successful': 'Оплата успешна',
    'General Details': 'Основные данные',
    'Your company brand/known name.': 'Название вашего бренда/компании',
    'Ex.:ProfiDental': 'Напр.: ПрофиДентал',
    'Company description': 'Описание компании',
    'Describe your company in few words so no one wants to read long text.': 'Несколько слов о вашей компании',
    'Ex.: We are experts in dental care': 'Прим.: Мы эксперты в области зубной защиты',
    'Public company': 'Публичная компания',
    'Making your profile public means that anyone on the 32Desk network will be able to find you': 'Публичные компании показываются в каталогах 32Desk',
    'No payments yet': 'Пока нет оплат',
    'from': 'от',
    'Total amount due': 'Итого к оплате',
    'Set password': 'Задать новый пароль',
    'Username': 'Имя пользователя',
    'Role': 'Роль',
    Doctor: 'Доктор',
    Administrator: 'Администратор',
    Director: 'Директор',
    'Finance manager': 'Финансовый менеджер',
    'Item has been saved': 'Запись сохранена',
    'Item has been created': 'Запись добавлена',
    'Hide past': 'Скрыть прошедшие',
    Color: 'Цвет',
    Manipulations: 'Манипуляции',
    Manipulation: 'Манипуляция',
    'Ex.: Anesthesia': 'Напр.: Анестезия',
    'Select manipulations': 'Выбрать манипуляции',
    'Add manipulation': 'Добавить манипуляцию',
    'Please select a customer': 'Выберите пользователя',
    'Week': 'Неделя',
    'Day': 'День',
    'Days': 'Дни',
    'Hours': 'Часы',
    'Template': 'Шаблон',
    'Time templates': 'Шаблоны времени',
    'New template': 'Новый шаблон',
    'Add time template': 'Добавить шаблон времени',
    'Minutes': 'Минуты',
    '3 Days': '3 Дня',
    'mins ahead': ' | за минуту | за {n} минуты | за {n} минут',
    'hours ahead': ' | за {n} час | за {n} часа | за {n} часов',
    'days ahead': ' | за {n} день | за {n} дня | за {n} дней',
    'weeks ahead': ' | за {n} неделю | за {n} недели | за {n} недель',
    'months ahead': ' | за {n} месяц | за {n} месяца | за {n} месяцев',
    'Notify client': 'Уведомить клиента',
    'Note created': 'Заметка создана',
    Browse: 'Выбрать из списка',
    'Please select final diagnonsis': 'Выберите окончательный диагноз',
    'Sign in': 'Вход',
    'Access to your dashboard': 'Доступ в систему управления',
    'Forgot password?': 'Забыли пароль?',
    'Enter your password': 'Пароль вашего аккаунта',
    'Logging out': 'Выход',
    'See you soon': 'Ждем вас еще',
    'Done': 'Сделано',
    'User Activities': 'Лог действий',
    'Value': 'Значение',
    // 'N/A': '-',
    'Select diagnosis from ICD-10 list': 'Выберите диагноз из МКБ-10',
    'To Be Done': 'Нужно сделать',
    Action: 'Действие',
    'Add payment': 'Добавить оплату',
    'Cancellation reason. Ex.: Wrong payment': 'Причина отмены Прим. Ошибочная оплата',
    'Ex.: Treatment of caries': 'Прим. Лечение простого кариеса',
    'Payment cancellation': 'Отмена оплаты',
    'Payment cancelled': 'Оплата отменена',
    'Cancelled': 'Отменен',
    'Total paid': 'Всего оплачено',
    'Approved': 'Подтвержден',
    'Explanation': 'Описание',
    'User Balance': 'Баланс пользователя',
    'Debtors': 'Должники',
    'Outdated': 'Просрочено',
    'Tags': 'Тэги',
    'Payment status': 'Статус оплаты',
    'Payment from balance': 'Оплата со счета',
    'Debt details': 'Детали долга',
    'Total Payment': "Всего к оплате",
    'Paid amount': "Оплачено",
    'By gender': 'По полу',
    Income: 'Приход',
    'Invalid login': 'Неверный логин или пароль',
    'Average Bill': 'Средний счет',
    'Missing credentials': 'Введите логин и пароль',
    'Team member updated': 'Пользователь обновлен',
    Specialties: 'Специальности',
    'Static price': 'Статичная цена',
    'Static duration': 'Статичная длительность',
    'Allow log in': 'Разрешить авторизацию',
    'User balance': 'Баланс пользователя',
    'Your access has been restricted. Please contact your administrator to resolve this issue.': 'Ваш доступ был ограничен. Пожалуйства свежитесь с вашим администратором.',
    Roles: 'Роли',
    'Balance top up': 'Пополнение баланса',
    Resource: 'Ресурс',
    Permissions: 'Права доступа',
    'Paid partially': 'Оплачен не полностью',
    'Paid': 'Оплачен',
    'New role': 'Новая роль',
    View: 'Просматривать',
    'Edit role': 'Изменить роль',
    'new': 'Новый',
    'By payment type': 'По методу оплаты',
    'By team members': 'По членам команды',
    'By age': 'По возрасту',
    'Ex.:Sales manager': 'Прим. Менеджер по продажам',
    'Ex.:Can approve payments and manage billing': 'Прим. Может управлять финансами и складом',
    'my-team': 'Команда',
    'By day': 'По дням',
    'By status': 'По статусу',
    'Debt and prepaid': 'Долги и предоплаты',
    'my-company': 'Компания',
    'permissions': 'Права',
    'Most valuable team member': 'Наиболее ценный член команды',
    'Least valuable team member': 'Наименее ценный член команды',
    'Most valuable patient': 'Наиболее ценный пациент',
    'Least valuable patient': 'Наименее ценный пациент',
    'Most debt patient': 'Пациент с наибольшим долгом',
    'Most prepaid patient': 'Пациент с наибольшей предполатой',
    'Toppers': 'Топы',
    'roles': 'Роли',
    'users': 'Пользователи',
    'Add new role': 'Добавить роль',
    'Actions': 'Дествия',
    'See all': 'Смотреть все',
    'Changes to roles saved': 'Изменения в ролях обновлены',
    'Appointment created': 'Запись создана',
    'Treatment presets': 'Шаблоны лечений',
    'SMS template': 'Шаблон СМС сообщения',
    'Explanation of debt': 'Заметка',
    'Discount': 'Скидка',
    'Ex.: VIP': 'При.: VIP клиен',
    'Exam on': 'Осмотр от',
    'Done on': 'Сделано',
    'Per page': 'На страницу',
    'Tooth condition': "Состояние зуба",
    'Teeth and mouth condition': "Состояние зуба и полости рта",
    'Passport number': 'Номер паспорта',
    'Passport given by': 'Паспорт выдан',
    'Patient passport number': 'Номер паспорта пациента',
    'Patient age': 'Возраст пациента',
    'Patient gender': 'Пол пациента',
    'Patient diagnosis': 'Диагноз пациента',
    'Patient address': 'Адрес пациента',
    'Patient birth date': 'Дата рождения пациента',
    Earnings: 'Наработано',
    Revenue: 'Заработок',
    'Payed out': 'Выплачено',
    Earned: 'Заработано',
    'Left over': 'Остаток',
    'Doctor first name': 'Имя доктора',
    'Doctor last name': 'Фамилия доктора',
    'Patient passport given by': 'Паспорт выдан',
    Prepaid: 'Предоплата',
    Channel: 'Канал',
    'Transaction ID': '№ Транзакции',
    'Tooth does not have any exams yet': 'Зуб еще не осматривался',
    'Start examing tooth by clicking "New exam"': 'Начните первый осмотр зуба прямо сейчас',
    'Conclusion': 'Заключение',
    'Application form': 'Анкета 043',
    Sum: 'Сумма',
    Debt: 'Долг',
    'Emergency logout': 'Экстренный выход',
    'Are you sure you want to logout all of your team?': 'Вы уверенны что хотить разлогинить всех в вашей команде?',
    'appointment-perm': 'Записи',
    'customers-perm': 'Клиенты',
    'my-company-perm': 'Моя компания',
    'my-team-perm': 'Моя команда',
    'cart-perm': 'Корзина',
    'company-perm': 'Компания',
    'profile-perm': 'Профиль',
    'presets-perm': 'Шаблоны лечения',
    'payments-perm': 'Оплаты',
    'products-perm': 'Продукты и Склад',
    'reports-perm': 'Отчеты',
    'notifications-perm': 'Уведомления',
    'roles-perm': 'Роли и права доступа',
    'treatment-perm': 'Услуги и процедуры',
    'timeline-perm': 'Лог действий',
    'settings-perm': 'Настройки',
    'time-templates-perm': 'Шаблоны времени уведомлений',
    'force-logout-perm': 'Экстренный выход',
    'Pay another': 'Оплатить еще',
    'Back': 'Назад',
    'Pay': "Оплатить",
    'Payment': "Оплата",
    'Pay back': 'Сдачи',
    'Ex.: Pain': 'Прим. Боль',
    'Invoice closed': 'Счет закрыт',
    'Baby map': 'Детская карта',
    'Treatment price': 'Цена процедуры',
    'My 32Desk': 'Мой 32Desk',

    'Current and previous illnes': 'Пересенные и сопутсвующие заболевания',
    'Profession': 'Профессия',
    'X-ray examinations history': 'История рентгенологических обследований',
    'Document generated by': 'Сгенерировано при помощи',
    'Manipulations made': 'Проделанные манипуляции',
    'Products used': 'Использованные продукты',
    'Ministry of Healthcare': 'Министерство здравоохранения',
    'ProfileFormBy': 'Медицинская документация форма № 043/У    <br />Утверждена Уз.РССВ 10.05.2010',
    'Medical card of dental patient': 'Медицинская карта стоматологического больного',
    'Fullname': 'Фамилия, Имя, Отчество',

    'Recommendations made': 'Даны рекомендации',
    'Recommendations': 'Рекомендации',
    'Ex. rinse mouth twice a day': 'Прим. Полоскать рот два раза в день',
    'Elementary examination': 'Первичный осмотр',
    'Bite': 'Прикус',
    'Overjet': 'Глубокий',
    'Open bite': 'Открытый',
    'Crossbite': 'Перекресный',
    'Overbite': 'Дистальный (прогнатический)',
    'Underbite': 'Мезиальный (прогенический)',

    'Orthognathic': 'Ортогнатический',
    'Progenic': 'Прогенический',
    'Straight': 'Прямой',
    'Biprognatic': 'Бипрогнатический',
    'State of oral mucosa': 'Состояние слизистой  оболочки полости рта, десен, альвеолярных отростков и неба',
    'X-Ray and Lab examination results': 'Данные рентгеновских и лабораторных исследований',
    'Normal': 'Хороший ',
    'Satisfactory': 'Удовлетворительный',
    'Unsatisfactory': 'Неудовлетворительный',
    'Bad': 'Плохой',
    'Very Bad': 'Очень плохой',
    'Accept appointments': 'Принимает записи',
    'Percent from total revenue': 'Базовый процент от услуг',
    'By Staff': 'По сотрудникам',

    'Scheduled for today': 'Запланировано на сегодня',
    'Oral Hygiene Index': 'Индекс Гигиены',
    'Unknown': 'Неивестный',
    'POS': 'Точка продаж',
    'POS terminal': 'Терминал продаж',
    'Duplicate to tooth': 'Скопировать на зуб',
    'All done': 'Все сделано',
    'Apply': 'Применить',
    'All top': 'Все верхние',
    'All bottom': 'Все нижние',
    'Clear': 'Очистить',
    'By tooth': 'По зубам',
    'Remove': 'Удалить',
    'Appointment has been rescheduled': 'Запись изменена',
    'Do you confirm ?': 'Вы подтверждаете ?',
    'By source': 'По источнику',
    'Total earned': 'Всего наработано',
    'Total debt for end of period': 'Общий долг на конец периода',
    'Debt for period of time': 'Долгов за этот период',
    'No treatment templates': 'Нет шаблонов лечения',
    'Try creating first by saving your current exam': 'Сохраните протоколы лечения в ваши шаблоны, чтобы не повторять действия',
    'In debt': 'В долг',
    'Edit/Reschedule appointment': 'Изменение записи',
    Yes: 'Да',
    No: 'Нет',
    'Name Last name or Phone': 'Имя Фамилия или Телефон',
    'Phone number protect': 'Зашита номеров телефонов',
    'Do not show customer phone numbers if user does not have a seeAll permission': 'Не показывать номер телефона клиента если у пользователя нету разрешения на просмотр всех',
    'Stock leftover': 'Остаток на складе',
    'Initial quantity': 'Изначальное количество',
    'Total revenue': 'Всего заработано',
    'Other ways of examination': 'Дополнительные методы обследования',
    'Objectivly details': 'Данные объективного осмотра',
    Diary: 'Дневник',
    'Remind after': 'Напомнить через',
    'Reminder': 'Напоминание',
    'Start date': 'Начало выполнения',
    'Due date': 'Срок выполнения',
    'Select team member': 'Выберите члена команды',
    'Remind about a treatment': 'Напомнить о процедуре',
    'Remind about': 'Напомнить о',
    'End before': 'Закончить до',
    'Start': 'Начать',
    'Select a treatment to be repeated': 'Выберите процедуру которую хотели бы повторить',
    'Edit/Reschedule task': 'Изминеть/Перенести задачу',
    'Create appointment': 'Создать встречу',
    'Treatment received': 'Полученная услуга',
    'Upcoming treatment': 'Предстоящяя услуга',
    'Do not call after 8 p.m.': 'Не звонить после 8 вечера',
    'Trash': 'Корзина',
    'New task created': 'Новая задача создана',
    'My templates': 'Мои шаблоны',
    'Recently used': 'Недавние',
    'Calendar step': 'Шаг в календаре',
    'Deleted at': 'Удален в',
    'Do you confirm': 'Вы подтвреждаете',
    'This operation is permanent and can not be reversed.': 'Данная операция не обратима.',
    'Permanently delete': 'Удалить на всегда',
    'Recover': 'Восстановить',
    'Recently deleted': 'Недавно удаленные',
    'Successfully recovered': 'Успешно востановлен',
    'Permanently deleted': 'Безвозвратно удален',
    'Item has been added': 'Услуга добавлена',
    'phone-field': {
        'required': 'Обязательное поле'
    },
    'customer-field': {
        'required': 'Обязательное поле'
    },
    'invoice': {
        'print-preview': 'Предварительный счет',
        'preview-to': 'Предварительный счет для',
        'not-paid': 'Не оплачено',
    },
    'Сombine invoices': 'Объединить счета',
    // TODO: 'Subscription' from SubscriptionList.vue,
    'Oral cavity': 'Полость рта',
    'Show online appointments': 'Показать онлайн записи',
    'Drafts': 'Черновики',
    'Treatment select while scheduling an appointment': 'Выбор процедуры при создании записи',
    'Enabled: required, treatment will be included in the invoice': 'Вкл: обязателен, процедура добавляется в счет',
    'Disabled: not required, treatment will not be included in the invoice': 'Выкл: не обязателен, процедура не будет добавлена в счет',
    'Technican': 'Техник',
    'filters': {
        'by-completion-date': 'По дате сдачи',
    },
    'Created at': 'Создано',
    'Current list': 'Текущий список',
    'Total size': 'Общий размер',
    'Now': 'Сейчас',
    'When scheduled': 'При создании',
    'Upload date': 'Дата загрузки',
    'Size': 'Размер',
    'Ascending': 'По возрастанию',
    'Descending': 'По убыванию',
    'Sort by': 'Сортировать по',
    'Log out': 'Выход',
    'Phone number': 'Номер телефона',
    'Log in': 'Вход',
    'Enter the code from SMS or check your telegram account': 'Введите код из SMS или проверьте Ваш Telegram аккаунт',
    'You have enabled cloud password. Please, enter it here': 'У вас включён облачный пароль. Пожалуйста, введите его здесь',
    'Invalid phone number': 'Неверный номер телефона',
    'Invalid code': 'Неверный код',
    'Invalid password': 'Неверный пароль',
    'Too many attempts. Try again later': 'Слишком много попыток. Попробуйте позже',
    'Telegram integration is not configured': 'Интеграция с Telegram не настроена',
    'Configure': 'Настроить',
    'Scheduled': 'Запланированное',
    'Do not notify': 'Не уведомлять',
    'Add Note': 'Добавить заметку',
    'Select country': 'Выберите страну',
    'For example: USA, Finland, etc': 'Например: Узбекистан, Россия и т.д.',
    'Wrong API key': 'Неверный API ключ',
    'OnlinePBX API key': 'API ключ OnlinePBX',
    'OnlinePBX domain': 'Домен OnlinePBX',
    'OnlinePBX VoIP service integration': 'Интеграция VoIP сервиса OnlinePBX',
    'Integration saved successfully': 'Интеграция осущствлена успешно',
    'Logged out successfully': 'Выход осущствлён',
    'Domain should end with .onpbx.ru': 'Домен должен оканичиваться на .onpbx.ru',
    'OnlinePBX is not configured': 'Интеграция с OnlinePBX не настроена',
    'Waiting clinic to accept the call': 'Ждём начала вызова со стороны клиники',
    'Clinic has not accepted call': 'Клиника не приняла вызов',
    'Call is started': 'Вызов активен',
    'Call': 'Вызов',
    'No records for this call': 'Для этого вызова нет записей',
    'Online appointments': 'Онлайн записи',
    'Appointment booking': 'Запись',
    'Online appointment booking': 'Онлайн запись',
    'Allows your clients to record themselves using a step-by-step form': 'Позволяет вашим клиентам делать запись самостоятельно используя пошаговую форму',
    'Skip doctor select': 'Пропустить выбор врача',
    'Disallows your clients to select the doctor': 'Отключить возможность выбора врача',
    'Skip time select': 'Пропустить выбор времени',
    'Disallows your clients to select the time for appointment': 'Отключить возможность выбора времени приёма',
    'Skip date select': 'Пропустить выбор даты приёма',
    'Disallows your clients to select the date for appointment': 'Отключить возможность выбора даты приёма',
    'Show teeth in invoice': 'Показать зубы в счётах оплат',
    'Show \'Tooth\' column in invoice': 'Показать столбец \'Зубы\' в счётах оплат',
    'Added successfully': 'Добавлено успешно',
    'Customers with uncompleted work order': 'Клиенты с незавершённым нарядом',
    'Edit treatment data after work order': 'Заполнение анкет',
    'Show additional page on work order to edit done treatments\' data': 'Показать дополнительную страницу для заполнения анкеты',
    'Note has been deleted': 'Заметка удалена',
    'Customer is archived': 'Клиент перенесён в архив',
    'Customer is updated': 'Клиент обновлён',
    'Customer is registered': 'Клиент зарегистрирован',
    'Add section': 'Добавить блок',
    'Ex.: Registration questionnaire': 'Пример: Опросник при регистариции',
    'Show in health card': 'Отображать в карте здоровья',
    'Title of section': 'Название блока',
    'email.subject': 'Сообщение от {company}',
    'Treatment has been deleted': 'Процедура удалена',
    'Default service language for new customer': 'Язык обслуживания для новых клиентов',
    'Language & Region': 'Язык и регион',

    'Notifications about upcoming appointments': 'Оповещение клиента о предстоящей записи в календаре',
    'Notifications about confirmation of online bookings': 'Оповещение клиента о подтверждении онлайн-записи в календаре',
    'Notifications about return of debt': 'Оповещение клиента о наличии долга',

    perm: {
        'customer-phone': 'Номер телефона клиента',
    }
}
