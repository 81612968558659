import PermissionCheck from './PermissionCheck.vue';
import cloneDeep from 'lodash/cloneDeep';
import cyclicTransit from 'cyrillic-to-translit-js';
import { useGetters } from 'vuex-composition-helpers';
import validator from 'email-validator';
function permute(nums) {
    let result = [];
    if (nums.length === 0)
        return [];
    if (nums.length === 1)
        return [nums];
    for (let i = 0; i < nums.length; i++) {
        const currentNum = nums[i];
        const remainingNums = nums.slice(0, i).concat(nums.slice(i + 1));
        const remainingNumsPermuted = permute(remainingNums);
        for (let j = 0; j < remainingNumsPermuted.length; j++) {
            const permutedArray = [currentNum].concat(remainingNumsPermuted[j]);
            result.push(permutedArray);
        }
    }
    return result;
}
export default {
    install(Vue, options = { store: null }) {
        Vue.component('permission-check', PermissionCheck);
        Vue.prototype.$validation = (errors, field) => {
            if (field in errors && errors[field])
                return false;
            else
                return null;
        };
        Vue.prototype.$mergeArrays = (array1, array2) => {
            return [...(array1 || []), ...(array2 || [])];
        };
        Vue.prototype.$checkDevice = $checkDevice;
        Vue.prototype.$pageFullScreen = (mode = false) => {
            class hideElement {
                constructor(el) {
                    if (el)
                        el.style.cssText = 'display:none !important';
                }
                ;
            }
            class showElement {
                constructor(el) {
                    if (el)
                        el.style.cssText = 'display:block';
                }
                ;
            }
            let topBar = document.querySelector("#app-top-bar");
            let sideBar = document.querySelector("#app-sidebar");
            if (mode) {
                new hideElement(topBar);
                new hideElement(sideBar);
            }
            else {
                new showElement(topBar);
                new showElement(sideBar);
            }
        };
        window.addEventListener('resize', () => {
            Vue.prototype.$screen.width = window.innerWidth;
            Vue.prototype.$screen.height = window.innerHeight;
        });
        Vue.prototype.$screen = Vue.observable({
            width: window.innerWidth,
            height: window.innerHeight
        });
        Vue.prototype.$dayOfWeek = (day) => {
            const dayOfweeksInt = {
                1: 'monday', 2: 'tuesday', 3: 'wednesday', 4: 'thursday', 5: 'friday', 6: 'saturday', 7: 'sunday'
            };
            const dayOfweeksName = {
                'monday': 1, 'tuesday': 2, 'wednesday': 3, 'thursday': 4, 'friday': 5, 'saturday': 6, 'sunday': 7
            };
            if (typeof day === 'string') {
                return dayOfweeksName[day];
            }
            return dayOfweeksInt[day];
        };
        Vue.prototype.$showBranches = (customers = false) => {
            const company = options.store.getters['my-company/get']('mine');
            if (!company.settings)
                return false;
            if (!customers)
                return company.settings.includeBranches;
            if (!company.branches)
                return company.settings.includeBranches;
            if (!company.branches.length)
                return company.settings.includeBranches;
            const parent = company.branches.find((el) => !el.parentId);
            if (!parent)
                return company.settings.includeBranches;
            if (!parent.settings)
                return company.settings.includeBranches;
            return parent.settings.seeAllCustomers;
        };
        Vue.prototype.$escapeRegex = (val) => {
            return val.replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&');
        };
        Vue.prototype.$sortArrayByArray = (arr, sortByArr, callback) => {
            return arr.slice().sort((a, b) => {
                return sortByArr.findIndex(el => callback(el, a)) - sortByArr.findIndex(el => callback(el, b));
            });
        };
        Vue.prototype.$n2br = $n2br;
        Vue.prototype.$toLatinRegex = function (val) {
            let reformattedVal = val.toLowerCase()
                .replace('дж', 'j');
            return cyclicTransit()
                .transform(reformattedVal)
                .replace("kh", "(kh|h|x)");
        };
        Vue.prototype.$toCyrillicRegex = function (val) {
            let reformattedVal = val.toLowerCase()
                .replace('h', 'kh')
                .replace('x', 'kh');
            return cyclicTransit()
                .reverse(reformattedVal)
                .replace("е", "(е|ё)")
                .replace("ё", "(е|ё)")
                .replace('ы', '(ы|й)')
                // strange, that j hasn't been converted
                .replace('j', '(ж|дж)');
        };
        Vue.prototype.$startsWithRegex = function (val) {
            return '^(' + this.$toLatinRegex(val) + '|' + this.$toCyrillicRegex(val) + ')';
        };
        Vue.prototype.$customersSearch = function (val) {
            if (!val)
                return {};
            val = this.$escapeRegex(val.trim());
            if (val.match(new RegExp(/^[0-9]+$/)))
                return { phone: { $regex: this.$phoneRegex(val), $options: "si" } };
            let parts = val.split(' ');
            if (parts.length > 1)
                return {
                    $or: permute(parts).map(([a, b, c]) => {
                        let q = {
                            first_name: { $regex: this.$startsWithRegex(a), $options: 'si' },
                            last_name: { $regex: this.$startsWithRegex(b), $options: "si" }
                        };
                        if (c)
                            q.middle_name = { $regex: this.$startsWithRegex(c), $options: "si" };
                        return q;
                    })
                };
            const keyword = this.$startsWithRegex(val);
            return {
                $or: [
                    { first_name: { $regex: keyword, $options: 'si' } },
                    { last_name: { $regex: keyword, $options: "si" } },
                    { middle_name: { $regex: keyword, $options: "si" } },
                ]
            };
        };
        Vue.prototype.$phoneRegex = function (val) {
            return '[\\s+(\\)-]*' + Array.from(this.$escapeRegex(val)).join('[\\s+(\\)-]*');
        };
        Vue.prototype.$secs2dhm = $secs2dhm;
        Vue.prototype.$dhm2secs = (days, hours, minutes) => {
            return days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60;
        };
        Vue.prototype.$secs2text = function (seconds) {
            let { days, hours, minutes } = this.$secs2dhm(seconds);
            days = days ? this.$tc('days ahead', days) : '';
            hours = hours ? (days ? hours + this.$t('h') : this.$tc('hours ahead', hours)) : '';
            minutes = minutes ? (hours ? minutes + this.$t('min') : this.$tc('mins ahead', minutes)) : '';
            return `${days} ${hours} ${minutes}`;
        };
        Vue.prototype.$dhm2text = function (days, hours, minutes) {
            days = days ? this.$tc('days ahead', days) : '';
            hours = hours ? (days ? hours + this.$t('h') : this.$tc('hours ahead', hours)) : '';
            minutes = minutes ? (hours ? minutes + this.$t('min') : this.$tc('mins ahead', minutes)) : '';
            return `${days} ${hours} ${minutes}`;
        };
        Vue.prototype.$groupBy = $groupBy;
        Vue.prototype.$copy = function (element) {
            return JSON.parse(JSON.stringify(element));
        };
        Vue.prototype.$deepCopy = $deepCopy;
        Vue.prototype.$isEmpty = function (element) {
            return element === null || element === undefined;
        };
        Vue.prototype.$unique = function (element) {
            return element.filter(function (value, index, self) {
                return self.indexOf(value) === index;
            });
        };
        Vue.prototype.$randomString = randomString;
        Vue.prototype.$usa2fdi = function (tooth) {
            const mapping = {
                'A': 4,
                'B': 5,
                'C': 6,
                'D': 7,
                'E': 8,
                'F': 9,
                'G': 10,
                'H': 11,
                'I': 12,
                'J': 13,
                'T': 29,
                'S': 28,
                'R': 27,
                'Q': 26,
                'P': 25,
                'O': 24,
                'N': 23,
                'M': 22,
                'L': 21,
                'K': 20
            };
            if (typeof tooth === 'string')
                return this.$usa2fdi(mapping[tooth]);
            const section = Math.ceil(tooth / 8); // 4
            const toothIndex = tooth % 8 ? tooth % 8 : 8;
            return 10 * section + 9 * (section % 2) + Math.pow((-1), section) * toothIndex;
        };
        Vue.directive('uppercase', {
            inserted: function (el, _, vnode) {
                el.addEventListener('input', async function (e) {
                    let values = e.target.value.split(' ');
                    values = values.map((value) => {
                        return value.charAt(0).toUpperCase() + value.slice(1);
                    });
                    e.target.value = values.join(' ');
                    if (vnode && vnode.componentInstance)
                        vnode.componentInstance.$emit('input', e.target.value);
                });
            }
        });
    }
};
export function $secs2dhm(seconds) {
    return {
        days: Math.floor(seconds / (60 * 60 * 24)),
        hours: Math.floor((seconds % (24 * 60 * 60) / (60 * 60))),
        minutes: Math.floor((seconds % (60 * 60)) / 60),
        seconds: seconds % 60
    };
}
export function $n2br(text) {
    if (!text)
        return '';
    return text.replace(/\n/g, '<br>');
}
export function $deepCopy(element) {
    return cloneDeep(element);
}
export function $checkDevice() {
    let check = false;
    (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
            check = true;
    })(navigator.userAgent || navigator.vendor);
    return check;
}
export function $loadModule(module) {
    const { get } = useGetters('my-company', ['get']);
    const company = get.value('mine');
    return company.module[module];
}
export function $groupBy(elements, key) {
    return elements.reduce((r, a) => {
        r[key(a)] = [...r[key(a)] || [], a];
        return r;
    }, {});
}
export function validateEmail(email) {
    return validator.validate(email);
}
export function randomString(length = 8) {
    let text = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}
